import { memo } from 'react';

function Footer() {
  return (
    <div
      id='footer'
      className='flex-shrink-1 d-flex flex-row d-none d-md-block border-top bg-primary'
    >
      <div id='footer_links' className='flex-grow-1 my-auto mx-auto p-2 small'>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://www.colostate.edu/contact/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact CSU
          </a>
        </span>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://www.colostate.edu/disclaimer/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Disclaimer
          </a>
        </span>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://www.colostate.edu/equal-opportunity/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Equal Opportunity
          </a>
        </span>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://www.colostate.edu/privacy/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Statement
          </a>
        </span>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://gdpr.colostate.edu/'
            target='_blank'
            rel='noopener noreferrer'
          >
            GDPR Statement
          </a>
        </span>
        <span className='mx-2'>
          <a
            className='link-light'
            href='https://canvas.colostate.edu/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Canvas
          </a>
        </span>
      </div>
    </div>
  );
}

export default memo(Footer);
