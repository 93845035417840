import Extend from '../extend/Extend';
import Grade from '../grade/Grade';
import Nudge from '../nudge/Nudge';
import Sidebar from '../reusable/Sidebar';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { memo, useEffect, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import getAssignmentsForCourse from '../../api/assignments_api';
import getStudentsForCourse from '../../api/students_api';
import {
  getAssignmentPage,
  getCoursePage,
  getUserPage,
} from '../../util/canvas_url';
import Error from '../reusable/Error';
import ExpandableCard from '../reusable/ExpandableCard';

export function ExtendName() {
  return (
    <div className='text-center'>
      <i className='bi bi-clock-fill' />
      <div>Extend</div>
    </div>
  );
}

export function NudgeName() {
  return (
    <div className='text-center'>
      <i className='bi bi-hand-index-thumb-fill' />
      <div>Nudge</div>
    </div>
  );
}

export function GradeName() {
  return (
    <div className='text-center'>
      <i className='bi bi-robot' />
      <div>Grade</div>
    </div>
  );
}

function Course({ courseInfo, enrollType }) {
  const [students, setStudents] = useState(null);
  const [assignments, setAssignments] = useState(null);

  useEffect(() => {
    setStudents(null);
    setAssignments(null);
    getStudentsForCourse(courseInfo.id).then(setStudents);
    getAssignmentsForCourse(courseInfo.id).then(setAssignments);
  }, [courseInfo.id]);

  const element = (
    <div className='p-4 text-primary border shadow-sm rounded-2 bg-white m-4'>
      <div className='fw-bold fs-5 mx-4 mb-4'>
        <a
          className='text-primary'
          href={getCoursePage(courseInfo.id)}
          target='_blank'
          rel='noopener noreferrer'
        >
          {courseInfo.name}
        </a>
      </div>
      <Row xs={1} md={2} className='justify-content-center text-center'>
        <Col>
          <ExpandableCard headerStyle='fw-bold p-2 rounded-top-2 border-bottom bg-light'>
            <>Students</>
            <ListGroup variant='flush'>
              {students &&
                students.map((stu, i) => (
                  <ListGroup.Item
                    key={i}
                    action
                    as={'a'}
                    className='text-dark small'
                    href={getUserPage(courseInfo.id, stu.id)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {stu.name}
                  </ListGroup.Item>
                ))}
              {students !== null && students.length === 0 && (
                <ListGroup.Item>No students...</ListGroup.Item>
              )}
              {students === null && <ListGroup.Item>Loading...</ListGroup.Item>}
            </ListGroup>
          </ExpandableCard>
        </Col>
        <Col>
          <ExpandableCard headerStyle='fw-bold p-2 rounded-top-2 border-bottom bg-light'>
            <>Assignments</>
            <ListGroup variant='flush'>
              {assignments &&
                assignments.map((assignment, i) => (
                  <ListGroup.Item
                    key={i}
                    action
                    as={'a'}
                    className='text-dark small'
                    href={getAssignmentPage(courseInfo.id, assignment.id)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {assignment.name}
                  </ListGroup.Item>
                ))}
              {assignments !== null && assignments.length === 0 && (
                <ListGroup.Item>No assignments...</ListGroup.Item>
              )}
              {assignments === null && (
                <ListGroup.Item>Loading...</ListGroup.Item>
              )}
            </ListGroup>
          </ExpandableCard>
        </Col>
      </Row>
    </div>
  );

  const items = [
    { name: null, path: '', element: element },
    {
      name: <ExtendName />,
      path: 'extend',
      element:
        enrollType === 'ta' ? (
          <Error
            error={'Unauthorized'}
            message={
              'You must be a Teacher or an Assistant Teacher to use Extend. \
              If you are a TA and need to use this tool, please ask the instructor of the course.'
            }
          />
        ) : (
          <Extend
            courseId={courseInfo.id}
            students={students}
            assignments={assignments}
          />
        ),
    },
    {
      name: <NudgeName />,
      path: 'nudge',
      element: (
        <Nudge
          courseId={courseInfo.id}
          students={students}
          assignments={assignments}
        />
      ),
    },
    {
      name: <GradeName />,
      path: 'grade',
      element: (
        <Grade
          courseId={courseInfo.id}
          courseCode={courseInfo.code}
          students={students}
          assignments={assignments && assignments.filter((x) => !x.is_quiz)}
        />
      ),
    },
  ];

  return <Sidebar items={items} />;
}

export default memo(Course);
