import { createContext, memo, useContext } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import Help from '../reusable/Help';
import {
  GradeFEHelp,
  GradeKeepHighestHelp,
  GradeNetworkingHelp,
  GradeTasksHelp,
  GradeTypeHelp,
  GradeVersionsHelp,
} from './Constants';
import GradeFile from './GradeFile';
import TaskComponents from './TaskComponents';

const TaskContext = createContext();
export const useTasks = () => useContext(TaskContext);

function GeneralConfig({
  formData,
  setFormData,
  tasks,
  setTasks,
  editState,
  fileData,
  setFileData,
  validDays,
  handleFileDownload,
}) {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const [key, index] = name.split('.');

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [key]: { ...formData[key], [index]: checked },
      });
    } else {
      setFormData({
        ...formData,
        [key]: { ...formData[key], [index]: Number(value) },
      });
    }
  };

  return (
    <>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck1'
          label={`Active${formData.active ? '' : '?'}`}
          type={'switch'}
          checked={formData.active}
          onChange={(e) => {
            setFormData({ ...formData, active: e.target.checked });
          }}
        />
      </fieldset>
      <hr />
      <Form.Label>
        Choose Autograder Type
        <Help title='Autogader Types' body={<GradeTypeHelp />} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck2'
          type='radio'
          label='Run Continuously'
          name='configType'
          onChange={() => setFormData({ ...formData, config_type: 'delay' })}
          checked={formData.config_type === 'delay'}
        />
        <Form.Check
          id='gradeCheck3'
          type='radio'
          label='Run at Specific Times'
          name='configType'
          onChange={() => setFormData({ ...formData, config_type: 'schedule' })}
          checked={formData.config_type === 'schedule'}
        />
        <br />
        {formData.config_type === 'delay' && (
          <Row>
            <Form.Label>Delay to Set</Form.Label>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm'>
                <Form.Control
                  type={'number'}
                  value={formData.delay_minutes.hours}
                  max={24}
                  min={0}
                  required
                  onChange={handleChange}
                  name='delay_minutes.hours'
                />
                <InputGroup.Text>hours</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm'>
                <Form.Control
                  type={'number'}
                  value={formData.delay_minutes.mins}
                  max={59}
                  min={0}
                  required
                  onChange={handleChange}
                  name='delay_minutes.mins'
                />
                <InputGroup.Text>mins</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  Allowed Days: 0-7, Allowed Hours: 0-23, Allowed Mins: 0-59
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
        {formData.config_type === 'schedule' && (
          <Row>
            <Form.Label>Days When Grader Runs</Form.Label>
            <Col sm={'auto'}>
              <Form.Group className='border rounded-1 p-1'>
                <Form.Check
                  id='gradeCheck4'
                  inline
                  name='days.0'
                  label='Sun'
                  checked={formData.days[0]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck5'
                  inline
                  name='days.1'
                  label='Mon'
                  checked={formData.days[1]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck6'
                  inline
                  name='days.2'
                  label='Tue'
                  checked={formData.days[2]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck7'
                  inline
                  name='days.3'
                  label='Wed'
                  checked={formData.days[3]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck8'
                  inline
                  name='days.4'
                  label='Thu'
                  checked={formData.days[4]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck9'
                  inline
                  name='days.5'
                  label='Fri'
                  checked={formData.days[5]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
                <Form.Check
                  id='gradeCheck10'
                  inline
                  name='days.6'
                  label='Sat'
                  checked={formData.days[6]}
                  onChange={handleChange}
                  isInvalid={validDays}
                />
              </Form.Group>
            </Col>
            <br />
            <Form.Label>Hour When Grader Runs</Form.Label>
            <Col sm={'auto'}>
              <InputGroup size='sm'>
                <Form.Control
                  type={'number'}
                  value={formData.hour}
                  max={23}
                  min={0}
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      hour: parseInt(e.target.value),
                    });
                  }}
                  name='hour'
                />
                <InputGroup.Text>: 00 MT</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  Hour must be between 0 and 23
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
      </fieldset>
      <br />
      <Form.Label>
        Network Configuration
        <Help title='Configure Networking' body={<GradeNetworkingHelp />} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck11'
          label={'Enable Networking (SSH/Internet/Sockets)'}
          type={'checkbox'}
          checked={formData.networking}
          onChange={(e) => {
            setFormData({ ...formData, networking: e.target.checked });
          }}
        />
      </fieldset>
      <br />
      <Form.Label>
        Canvas Settings
        <Help title='Canvas Settings' body={<GradeKeepHighestHelp />} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck12'
          label={'Keep Latest Grade'}
          type={'radio'}
          name='canvasType'
          checked={!formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: false });
          }}
        />
        <Form.Check
          id='gradeCheck13'
          label={'Keep Highest Grade'}
          type={'radio'}
          name='canvasType'
          checked={formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: true });
          }}
        />
      </fieldset>
      <br />
      <Form.Label>
        Software Versions
        <Help title='Grader Software Versions' body={<GradeVersionsHelp />} />
      </Form.Label>
      <fieldset
        disabled={!editState}
        className='border border-1'
        style={{ overflowY: 'scroll', height: '170px', maxWidth: '400px' }}
      >
        <Table striped bordered responsive size='sm' className='small m-0'>
          <tbody>
            <tr>
              <td>Java</td>
              <td>
                <Form.Select
                  size='sm'
                  value={formData.versions.java}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ['versions']: {
                        ...formData['versions'],
                        ['java']: e.target.value,
                      },
                    })
                  }
                >
                  <option value={'21'}>21</option>
                  <option value={'11'}>11</option>
                  <option value={'8'}>8</option>
                </Form.Select>
              </td>
            </tr>
            <tr>
              <td>Python</td>
              <td>
                <Form.Select
                  size='sm'
                  value={formData.versions.python}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ['versions']: {
                        ...formData['versions'],
                        ['python']: e.target.value,
                      },
                    })
                  }
                >
                  <option value={'3.12'}>3.12</option>
                  <option value={'3.10'}>3.10</option>
                  <option value={'3.8'}>3.8</option>
                </Form.Select>
              </td>
            </tr>
            <tr>
              <td>Make</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>GCC</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>G++</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>Git</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>Maven</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>Gradle</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>NodeJS</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>npm</td>
              <td>Latest</td>
            </tr>
            <tr>
              <td>Rust</td>
              <td>Latest</td>
            </tr>
          </tbody>
        </Table>
      </fieldset>
      {(editState || fileData) && (
        <>
          <br />
          <Form.Label>
            Grading Assets
            <Help title='Upload Grader File' body={<GradeFEHelp />} />
          </Form.Label>
          <fieldset disabled={!editState}>
            {fileData === null ? (
              <Col sm={'10'}>
                <GradeFile fileData={fileData} setFileData={setFileData} />
              </Col>
            ) : (
              <div
                className='d-flex align-items-center bg-light rounded-2'
                style={{ width: 'fit-content' }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0px 10px',
                  }}
                >
                  {fileData?.name}
                </span>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => setFileData(null)}
                >
                  <i
                    className={
                      'bi bi-trash-fill' + (editState ? ' text-danger' : '')
                    }
                  ></i>
                </Button>
                <Button variant='light' size='sm' onClick={handleFileDownload}>
                  <i
                    className={
                      'bi bi-cloud-arrow-down-fill' +
                      (editState ? ' text-info' : '')
                    }
                  ></i>
                </Button>
              </div>
            )}
          </fieldset>
        </>
      )}
      <br />
      <Form.Label>
        Tasks
        <Help title='Tasks Configuration' body={<GradeTasksHelp />} />
      </Form.Label>
      <TaskContext.Provider value={{ tasks, setTasks, editState }}>
        <TaskComponents />
      </TaskContext.Provider>
    </>
  );
}

export default memo(GeneralConfig);
