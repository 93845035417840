import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

function GradeFile({ fileData, setFileData }) {
  const handleOnDrop = (acceptedFiles) => {
    setFileData(acceptedFiles[0]);
  };

  const { fileRejections, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      noKeyboard: true,
      maxSize: 2 ** 20 * 15,
      onDrop: handleOnDrop,
      maxFiles: 1,
      multiple: false,
    });

  const baseStyle = {
    padding: '20px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    overflowY: 'hidden',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? { borderColor: '#1e4d2b' } : {}),
    }),
    [isDragAccept],
  );

  const rejectedItems = fileRejections.map(({ file, errors }) => (
    <p className='text-danger small' key={file.name}>
      {`Error uploading ${file.name} : ${errors[0].code}`}
    </p>
  ));

  return (
    <div className='flex-grow-1'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {fileData === null ? (
          <span>
            Drag & drop a file here, or click to select file...{rejectedItems}
          </span>
        ) : (
          <span>Uploaded File: {fileData?.name}</span>
        )}
      </div>
    </div>
  );
}

export default memo(GradeFile);
