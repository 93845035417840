import { Accordion, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { memo, useState } from 'react';
import toast from 'react-hot-toast';
import { addRule, updateRule } from '../../api/nudge_api';
import Confirm from '../reusable/Confirm';
import Help from '../reusable/Help';
import Mentions from '../reusable/Mentions';
import MultipleSearchSelect from '../reusable/MultipleSearchSelect';
import { KW_RE, NUDGE_KWS } from './Constants';
import NudgeHelp from './NudgeHelp';
import Visualize from './Visualize';

function RuleCard({
  entry,
  deleteRule,
  dupRule,
  setRules,
  setEditState,
  editState,
  students,
  assignments,
}) {
  const [showModal, setShowModal] = useState(null); //show modal when deleting rule
  const [validated, setValidated] = useState(false); //check form is validate when submitting
  const [renderRule, setRenderRule] = useState(false); //re-render rule card when changes are made/canceled changes
  const [formData, setFormData] = useState(entry); //a copy of prop used to revert back to original
  const [validDays, setValidDays] = useState(false); //used to check if at least one day is selected
  const [fixErrorsMsg, setFixErrorsMsg] = useState(''); //used to display error message if the form is not valid

  //keeps track of changes in the form for regular input types
  const handleChange = (e) => {
    let { name, value } = e.target;
    value =
      !isNaN(Number(value)) && Number(value) <= 100
        ? Number(value)
        : String(value);
    setFormData({ ...formData, [name]: value });
  };

  //keeps track of changes in the form for checked input types
  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    if (name.includes('.')) {
      const [key, index] = name.split('.');
      setFormData({
        ...formData,
        [key]: { ...formData[key], [index]: checked },
      });
    } else {
      setFormData({ ...formData, [name]: checked });
    }
  };

  //keeps track of changes in the form for buttons
  const handleChangeRadio = (e) => {
    let { name, value } = e.target;
    value = value === 'true';
    setFormData({ ...formData, [name]: value });
  };

  //handle the case when a rule is deleted
  const handleDeleteRule = () => {
    setShowModal(
      <Confirm
        question='Are you sure you want to delete this rule?'
        onConfirm={() => deleteRule(entry._id)}
        onCancel={() => setShowModal(null)}
      />,
    );
  };

  //handle the case when the button to edit the rule is clicked
  const handleEditRule = (e) => {
    e.preventDefault();
    setEditState(entry._id);
  };

  //resets all values to false and rerenders component
  const reset = () => {
    setRenderRule((prevState) => !prevState);
    setEditState(null);
    setFixErrorsMsg('');
    setValidDays(false);
  };

  //handle the case where the cancel button is clicked when editing
  const handleCancelEdit = (e) => {
    e.preventDefault();
    if (entry._id === 0) deleteRule(entry._id);
    setFormData(entry.rule);
    reset();
  };

  //validates the body to ensure proper substitution is used
  const checkBodyIsValid = (body) => {
    const strBody = String(body);
    const allowed_kw = NUDGE_KWS.map((kw) => kw.id);
    if (strBody.length < 1) return 'Required!';

    const arr = strBody.matchAll(KW_RE);

    for (const entry of arr) {
      if (!allowed_kw.includes(entry[1].toLowerCase()))
        return `${entry[1]} is not a valid keyword!`;
    }
    return 'ok';
  };

  //handle the case where the edits made to the rule is saved
  const handleSaveEdit = (e) => {
    const errMsg = 'Please fix errors before submitting!';
    e.preventDefault();

    if (!Object.values(formData.daysToSendEmails).some((value) => value)) {
      setValidDays(true);
      setFixErrorsMsg(errMsg);
      return;
    }

    const subMsg = checkBodyIsValid(formData.subject);
    if (subMsg !== 'ok') {
      setFixErrorsMsg(`Error in subject: ${subMsg}`);
      return;
    }

    if (formData.unsubmitted) {
      const msg = checkBodyIsValid(formData.unsubBody);
      if (msg !== 'ok') {
        setFixErrorsMsg(`Error in unsub body: ${msg}`);
        return;
      }
    }

    if (formData.thresholdExists) {
      const msg = checkBodyIsValid(formData.btBody);
      if (msg !== 'ok') {
        setFixErrorsMsg(`Error in threshold body: ${msg}`);
        return;
      }
    }

    if (!formData.unsubmitted && !formData.thresholdExists) {
      const msg = checkBodyIsValid(formData.genBody);
      if (msg !== 'ok') {
        setFixErrorsMsg(`Error in message body: ${msg}`);
        return;
      }
    }

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      setFixErrorsMsg(errMsg);
      e.stopPropagation();
    } else {
      if (entry._id === 0) {
        addRule(entry.course_id, formData)
          .then((response) => {
            setRules((prevList) =>
              prevList.map((obj) =>
                obj._id === 0
                  ? {
                      _id: response.rule_id,
                      course_id: entry.course_id,
                      ...formData,
                    }
                  : obj,
              ),
            );
            reset();
            toast.success('Added new rule successfully!');
          })
          .catch(() => toast.error('Failed to add new rule!'));
      } else {
        updateRule(entry.course_id, entry._id, formData)
          .then(() => {
            setRules((prevList) =>
              prevList.map((obj) =>
                obj._id === entry._id
                  ? { _id: entry._id, course_id: entry.course_id, ...formData }
                  : obj,
              ),
            );
            reset();
            toast.success('Updated rule successfully!');
          })
          .catch(() => toast.error('Failed to update rule!'));
      }
    }
  };

  //used for the accordian to create a custom header that can be expanded
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    return (
      <button
        className='border-0 bg-primary text-light text-start fw-bold p-2 d-flex w-100 rounded-top-2'
        onClick={decoratedOnClick}
        disabled={editState !== null && editState !== entry._id}
      >
        {children}
      </button>
    );
  }

  //returning the rule card; involves the card's header, the collapsible body with the form, and the applicable buttons
  return (
    <div className='shadow m-4' key={renderRule}>
      <CustomToggle eventKey={entry._id}>
        <div className='flex-grow-1'>{formData.name}</div>
        {formData.active ? (
          <div>
            Active
            <i className='bi bi-check-circle-fill ms-2'></i>
          </div>
        ) : (
          <div>
            Inactive
            <i className='bi bi-exclamation-circle-fill ms-2'></i>
          </div>
        )}
      </CustomToggle>
      <Accordion.Collapse eventKey={entry._id}>
        <div className='bg-white py-2 px-3 rounded-bottom-2'>
          <Form
            onSubmit={handleSaveEdit}
            noValidate
            validated={validated}
            className='px-2'
          >
            <fieldset disabled={editState === null}>
              <Row>
                <Col sm={5}>
                  <Form.Label>Rule Name</Form.Label>
                  <Form.Control
                    size='sm'
                    type={'text'}
                    defaultValue={formData.name}
                    required
                    onChange={handleChange}
                    name='name'
                  />
                  <Form.Control.Feedback type='invalid'>
                    Rule name required
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className='my-auto'>
                  <Form.Check
                    id='nudgeCheck1'
                    label={'Active?'}
                    type={'switch'}
                    defaultChecked={formData.active}
                    onChange={handleChangeCheck}
                    name='active'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='my-auto'>
                  <Form.Check
                    id='nudgeCheck2'
                    label={'Allow Students to Unsubscribe?'}
                    type={'switch'}
                    defaultChecked={formData.allowUnsubscribe}
                    onChange={handleChangeCheck}
                    name='allowUnsubscribe'
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Form.Label>Configure Assignment Dates to Target</Form.Label>
                <Col sm={'auto'}>
                  <Form.Check
                    id='nudgeCheck3'
                    type='radio'
                    label='Due Date'
                    name='dueNotLate'
                    value={true}
                    onChange={handleChangeRadio}
                    defaultChecked={formData.dueNotLate}
                  />
                  <Form.Check
                    id='nudgeCheck4'
                    type='radio'
                    label='Late Date'
                    name='dueNotLate'
                    value={false}
                    onChange={handleChangeRadio}
                    defaultChecked={!formData.dueNotLate}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Form.Label>Send Message if</Form.Label>
                <Col sm={'auto'}>
                  <Form.Check
                    id='nudgeCheck5'
                    name='unsubmitted'
                    label='Assignment is Unsubmitted'
                    defaultChecked={formData.unsubmitted}
                    onChange={handleChangeCheck}
                  />
                  <Form.Check
                    id='nudgeCheck6'
                    name='thresholdExists'
                    label='Assignment Grade is Less Than '
                    defaultChecked={formData.thresholdExists}
                    onChange={handleChangeCheck}
                  />
                </Col>
              </Row>
              {formData.thresholdExists && (
                <Row>
                  <Col sm={'auto'}></Col>
                  <Col sm={'auto'}>
                    <InputGroup size='sm'>
                      <Form.Control
                        type={'number'}
                        defaultValue={formData.gradeThreshold}
                        max={100}
                        min={1}
                        required
                        onChange={handleChange}
                        name='gradeThreshold'
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        Grade threshold must be between 1-100
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <hr />
              {formData.dueNotLate && (
                <>
                  <Row>
                    <Form.Label>Number of Days Before Submission</Form.Label>
                    <Col sm={'auto'}>
                      <InputGroup size='sm'>
                        <Form.Control
                          type={'number'}
                          defaultValue={formData.daysBeforeSubmission}
                          max={30}
                          min={1}
                          required
                          onChange={handleChange}
                          name='daysBeforeSubmission'
                        />
                        <InputGroup.Text>days</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          Number of days must be within 1-30
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              <Row>
                <Form.Label>Days to Send Message</Form.Label>
                <Col sm={'auto'}>
                  <Form.Group className='border rounded-1 p-1'>
                    <Form.Check
                      id='nudgeCheck7'
                      inline
                      name='daysToSendEmails.0'
                      label='Sun'
                      defaultChecked={formData.daysToSendEmails[0]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck8'
                      inline
                      name='daysToSendEmails.1'
                      label='Mon'
                      defaultChecked={formData.daysToSendEmails[1]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck9'
                      inline
                      name='daysToSendEmails.2'
                      label='Tue'
                      defaultChecked={formData.daysToSendEmails[2]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck10'
                      inline
                      name='daysToSendEmails.3'
                      label='Wed'
                      defaultChecked={formData.daysToSendEmails[3]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck11'
                      inline
                      name='daysToSendEmails.4'
                      label='Thu'
                      defaultChecked={formData.daysToSendEmails[4]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck12'
                      inline
                      name='daysToSendEmails.5'
                      label='Fri'
                      defaultChecked={formData.daysToSendEmails[5]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                    <Form.Check
                      id='nudgeCheck13'
                      inline
                      name='daysToSendEmails.6'
                      label='Sat'
                      defaultChecked={formData.daysToSendEmails[6]}
                      onChange={handleChangeCheck}
                      isInvalid={validDays}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Form.Label>Time to Send Message</Form.Label>
                <Col sm={'auto'}>
                  <InputGroup size='sm'>
                    <Form.Control
                      type={'number'}
                      defaultValue={formData.hourToSendEmails}
                      max={23}
                      min={0}
                      required
                      onChange={handleChange}
                      name='hourToSendEmails'
                    />
                    <InputGroup.Text>: 00 MT</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>
                      Hour must be between 0 and 23
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <br />
              {students && (
                <Row>
                  <Form.Label>Students to Include</Form.Label>
                  <MultipleSearchSelect
                    items={students}
                    selectedList={formData.studentsToInclude}
                    setSelectedList={setFormData}
                    title={'Students'}
                    keyIfNested={'studentsToInclude'}
                  />
                </Row>
              )}
              <br />
              {assignments && (
                <Row>
                  <Form.Label>Assignments to Include</Form.Label>
                  <MultipleSearchSelect
                    items={assignments}
                    selectedList={formData.assignmentsToInclude}
                    setSelectedList={setFormData}
                    title={'Assignments'}
                    keyIfNested={'assignmentsToInclude'}
                  />
                </Row>
              )}
              <br />
              <hr />
              <Row>
                <Form.Label>
                  Subject
                  <Help body={<NudgeHelp type={-1} />} />
                  <Visualize body={formData.subject} />
                </Form.Label>
                <Col sm={6}>
                  <Mentions
                    KWS={NUDGE_KWS}
                    value={formData.subject}
                    onChange={(_msg, val) =>
                      setFormData({ ...formData, ['subject']: val })
                    }
                    placeholder='Enter subject here'
                    singleLine
                  />
                </Col>
              </Row>
              {!formData.unsubmitted && !formData.thresholdExists && (
                <Row>
                  <Form.Label>
                    <br />
                    Message Body
                    <Help body={<NudgeHelp type={0} />} />
                    <Visualize body={formData.genBody} />
                  </Form.Label>
                  <Col sm={10}>
                    <Mentions
                      KWS={NUDGE_KWS}
                      value={formData.genBody}
                      onChange={(_msg, val) =>
                        setFormData({ ...formData, ['genBody']: val })
                      }
                    />
                  </Col>
                </Row>
              )}
              {formData.unsubmitted && (
                <Row>
                  <Form.Label>
                    <br />
                    Message Body for Unsubmitted Assignments
                    <Help body={<NudgeHelp type={1} />} />
                    <Visualize body={formData.unsubBody} />
                  </Form.Label>
                  <Col sm={10}>
                    <Mentions
                      KWS={NUDGE_KWS}
                      value={formData.unsubBody}
                      onChange={(_msg, val) =>
                        setFormData({ ...formData, ['unsubBody']: val })
                      }
                    />
                  </Col>
                </Row>
              )}
              {formData.thresholdExists && (
                <Row>
                  <Form.Label>
                    <br />
                    Message Body for Assignments Below Threshold
                    <Help body={<NudgeHelp type={2} />} />
                    <Visualize body={formData.btBody} />
                  </Form.Label>
                  <Col sm={10}>
                    <Mentions
                      KWS={NUDGE_KWS}
                      value={formData.btBody}
                      onChange={(_msg, val) =>
                        setFormData({ ...formData, ['btBody']: val })
                      }
                    />
                  </Col>
                </Row>
              )}
              <br />
            </fieldset>
            {editState === null ? (
              <div className='text-end border-top pt-2'>
                <Button
                  className='mx-1 text-light'
                  variant='info'
                  size='md'
                  onClick={() => dupRule(formData)}
                >
                  <i className='bi bi-copy'></i> Duplicate
                </Button>
                <Button
                  className='mx-1 text-light'
                  variant='info'
                  size='md'
                  onClick={handleEditRule}
                >
                  <i className='bi bi-pencil-fill'></i> Edit
                </Button>
                <Button
                  className='mx-1 text-light'
                  variant='danger'
                  size='md'
                  onClick={handleDeleteRule}
                >
                  <i className='bi bi-trash-fill'></i> Delete
                </Button>
              </div>
            ) : (
              <div className='text-end border-top pt-2'>
                <span className='text-danger small'>{fixErrorsMsg}</span>
                <Button
                  className='mx-1 text-light'
                  variant='info'
                  size='md'
                  type={'submit'}
                >
                  <i className='bi bi-floppy-fill'></i> Save
                </Button>
                <Button
                  className='mx-1 text-light'
                  variant='danger'
                  size='md'
                  onClick={handleCancelEdit}
                >
                  <i className='bi bi-ban'></i> Cancel
                </Button>
              </div>
            )}
          </Form>
        </div>
      </Accordion.Collapse>
      {showModal}
    </div>
  );
}

export default memo(RuleCard);
