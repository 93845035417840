import axios from 'axios';
import { BACKEND_URL } from './api.js';

export default function getAssignmentsForCourse(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/assignments')
    .then((response) =>
      response?.data?.sort((a, b) => a.name.localeCompare(b.name)),
    );
}

export function getGroupsForCourse(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/assignments/groups')
    .then((response) => response?.data);
}
