import { memo } from 'react';
import Table from 'react-bootstrap/Table';
import Mentions from '../reusable/Mentions';
import * as constants from './Constants';

function NudgeHelp({ type }) {
  return (
    <div className={'d-flex flex-column small'}>
      <h6>
        <b>Sample Messages</b>
      </h6>

      {type === -1 && (
        <>
          <label className='text-dark'>
            <b>Subject</b>
          </label>
          <Mentions
            value={constants.SAMPLE_SUBJECT_1}
            readOnly
            singleLine
            classes='bg-light my-1'
          />
          <div></div>
          <Mentions
            value={constants.SAMPLE_SUBJECT_2}
            readOnly
            singleLine
            classes='bg-light my-1'
          />
        </>
      )}

      {type === 0 && (
        <>
          <label className='text-dark'>
            <b>Reminder for Exams</b>
          </label>
          <Mentions
            value={constants.SAMPLE_EXAM}
            readOnly
            classes='bg-light my-1'
          />
        </>
      )}

      {type === 1 && (
        <>
          <label className='text-dark'>
            <b>No Submissions Before Deadline</b>
          </label>
          <Mentions
            value={constants.SAMPLE_UNSUB}
            readOnly
            classes='bg-light my-1'
          />
          <label className='text-dark'>
            <b>No Submission Before Late Deadline</b>
          </label>
          <Mentions
            value={constants.SAMPLE_LATE_UNSUB}
            readOnly
            classes='bg-light my-1'
          />
        </>
      )}

      {type === 2 && (
        <>
          <label className='text-dark'>
            <b>Submission Below Threshold Before Deadline</b>
          </label>
          <Mentions
            value={constants.SAMPLE_BT}
            readOnly
            classes='bg-light my-1'
          />
          <label className='text-dark'>
            <b>Submission Below Threshold Before Late Deadline</b>
          </label>
          <Mentions
            value={constants.SAMPLE_LATE_BT}
            readOnly
            classes='bg-light my-1'
          />
        </>
      )}

      <hr />
      <h6>
        <b>Help on Keywords</b>
      </h6>
      <p>
        You can use keywords inside your messages to denote that it should be
        substituted for something. These have to be wrapped with {'{{ }}'} and
        are enforced by the form. For example, Hello, {'{{student.name}}'}{' '}
        returns &quot;Hello, Jane Doe&quot;. They are case insensitive. See the
        table below for valid keywords and their purpose.
      </p>
      <Table striped bordered>
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Keyword</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {constants.NUDGE_KWS.map((kw) => (
            <tr key={kw.display}>
              <td>{kw.id}</td>
              <td>{kw.desc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h6>
        <b>Visualize all Keywords</b>
      </h6>
      <Mentions
        value={constants.NUDGE_KWS.map(
          (kw) => `${kw.id} - \x1E${kw.id}\x1E`,
        ).join('\n')}
        readOnly
        classes='bg-light my-1'
      />
    </div>
  );
}

export default memo(NudgeHelp);
