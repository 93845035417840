import axios from 'axios';
import { BACKEND_URL } from './api';

export function getLogs(courseId, assignmentId, queries, pageNumber) {
  queries.page = pageNumber;
  return axios.get(
    BACKEND_URL + '/courses/' + courseId + '/grade/' + assignmentId + '/logs',
    {
      params: queries,
      cache: false,
    },
  );
}

export function getLogOutputs(courseId, assignmentId, logId) {
  return axios.get(
    BACKEND_URL +
      '/courses/' +
      courseId +
      '/grade/' +
      assignmentId +
      '/logs/' +
      logId,
  );
}
