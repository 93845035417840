import { memo, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { testSubmission } from '../../api/grade_api';
import Help from '../reusable/Help';
import { getStatusTD } from './AssignmentGrade';
import { GradeTestHelp } from './Constants';
import GradeFile from './GradeFile';

function SubmissionTest({ courseId, assignmentId }) {
  const [fileData, setFileData] = useState(null);
  const [testOutput, setTestOutput] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleRunTestSub = (e) => {
    e.preventDefault();
    if (fileData === null) return;

    setDisabled(true);
    testSubmission(courseId, assignmentId, fileData)
      .then((response) => {
        setTestOutput(response.data);
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(false);
        toast.error('Failed to test submission!');
      });
  };

  return (
    <Form onSubmit={handleRunTestSub} className='p-3' noValidate>
      <fieldset disabled={disabled}>
        <Form.Label>
          Select Submission to Test
          <Help title='Submission Test' body={<GradeTestHelp />} />
        </Form.Label>
        <Row className='align-items-center'>
          <Col className='col-10'>
            <GradeFile fileData={fileData} setFileData={setFileData} />
          </Col>
          <Col>
            {fileData &&
              (disabled ? (
                <Spinner variant='info' />
              ) : (
                <Button
                  className='text-light'
                  variant='info'
                  size='md'
                  type={'submit'}
                >
                  <i className='bi bi-gear-fill'></i> Run
                </Button>
              ))}
          </Col>
        </Row>
        <br />
        {testOutput !== null && (
          <>
            <Form.Label>Test Results</Form.Label>
            <Table
              bordered
              size='sm'
              className='small bg-light overflow-scroll'
              style={{ maxHeight: '400px' }}
            >
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Grade</th>
                  <th>Duration</th>
                  <th>Comment</th>
                  <th>Stderr</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ whiteSpace: 'pre-wrap' }}>
                  {getStatusTD(testOutput.success)}
                  <td>{testOutput.grade}</td>
                  <td>{testOutput.duration.toFixed(1)}s</td>
                  <td>{testOutput.comment}</td>
                  <td>{testOutput.stderr}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </fieldset>
    </Form>
  );
}

export default memo(SubmissionTest);
