import { memo, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function Confirm({
  question = 'Are you sure?',
  confirmText = 'Yes',
  cancelText = 'No',
  confirmVariant = 'danger',
  cancelVariant = 'info',
  onConfirm,
  onCancel,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, [
    question,
    confirmText,
    cancelText,
    confirmVariant,
    cancelVariant,
    onConfirm,
    onCancel,
  ]);

  return (
    <Modal show={show} centered>
      <Modal.Header className='bg-primary text-light'>Confirm</Modal.Header>
      <Modal.Body>{question}</Modal.Body>
      <Modal.Footer className='p-0'>
        {cancelVariant && (
          <Button
            className={'text-light'}
            variant={cancelVariant}
            onClick={() => {
              setShow(false);
              if (onCancel) onCancel();
            }}
          >
            {cancelText}
          </Button>
        )}
        {confirmVariant && (
          <Button
            className={'text-light'}
            variant={confirmVariant}
            onClick={() => {
              setShow(false);
              if (onConfirm) onConfirm();
            }}
          >
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Confirm);
