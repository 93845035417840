import axios from 'axios';
import { BACKEND_URL } from './api.js';

export default function login(token = null) {
  return axios
    .post(BACKEND_URL + '/login', { token: token })
    .then((response) => response.data);
}

export function logout() {
  return axios.get(BACKEND_URL + '/logout', { cache: false });
}

export function getName() {
  return axios.get(BACKEND_URL + '/name').then((response) => response?.data);
}
