import axios from 'axios';
import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor';

let storage = null;

export function createCache() {
  if (storage !== null) return;
  storage = buildMemoryStorage();
  setupCache(axios, { storage, ttl: 1000 * 60 * 60 });
}

export function clearCache() {
  if (storage !== null) storage.data = Object.create(null);
}
