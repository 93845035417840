import { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Form,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

function MultipleSearchSelect({
  items,
  selectedList,
  setSelectedList,
  title,
  keyIfNested = null,
  height = '300px',
  width = '100%',
}) {
  const by = Object.prototype.hasOwnProperty.call(items[0], 'id')
    ? 'id'
    : 'name';
  const [unselectedListObj, setUnselectedListObj] = useState(null);
  const [selectedListObj, setSelectedListObj] = useState(null);
  const [unselectedItems, setUnselectedItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [unselectedSearch, setUnselectedSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');

  function filterBySearchVal(kw, iter) {
    if (iter === null) return;
    const filtered = iter.filter((x) => {
      const lcItem = String(x.name).toLowerCase();
      const allItems = [
        lcItem,
        ...lcItem.split(/[^a-zA-Z0-9]/),
        by === 'id' && String(x.id).toLowerCase(),
      ];
      for (const name of allItems) {
        if (name.startsWith(String(kw).toLowerCase())) return true;
      }
    });
    return filtered;
  }

  function handleUnselectedSearch(kw, iter = unselectedListObj) {
    setUnselectedItems(filterBySearchVal(kw, iter));
    setUnselectedSearch(kw);
  }

  function handleSelectedSearch(kw, iter = selectedListObj) {
    setSelectedItems(filterBySearchVal(kw, iter));
    setSelectedSearch(kw);
  }

  useEffect(() => {
    const ul = items.filter((x) => !selectedList.includes(x[by]));
    const sl = items.filter((x) => selectedList.includes(x[by]));
    setUnselectedListObj(ul);
    setSelectedListObj(sl);
    handleUnselectedSearch(unselectedSearch, ul);
    handleSelectedSearch(selectedSearch, sl);
  }, [selectedList]);

  function handleSelect(e, filt) {
    e.preventDefault();
    if (keyIfNested !== null)
      setSelectedList((prev) => ({ ...prev, [keyIfNested]: filt }));
    else setSelectedList(filt);
  }

  return (
    <div
      className='d-flex gap-2'
      style={{
        height: height,
        width: width,
      }}
    >
      <Card className='w-50'>
        <Card.Header className='small text-center fw-semibold'>{`Unselected ${title}`}</Card.Header>
        <div className='d-flex align-items-center bg-light py-1'>
          <div className='flex-grow-1 d-flex align-items-center bg-light ms-2 border rounded'>
            <span className='px-2'>
              <i className='bi bi-search' />
            </span>
            <Form.Control
              size='sm'
              className='border-0 rounded-0 bg-light'
              onChange={(e) => handleUnselectedSearch(e.target.value)}
            />
          </div>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Select All</Tooltip>}
          >
            <Button
              className='py-0 px-2 border-0'
              variant=''
              size='md'
              onClick={(e) =>
                handleSelect(
                  e,
                  items.map((x) => x[by]),
                )
              }
            >
              <i className='bi bi-plus-circle fs-5 text-success' />
            </Button>
          </OverlayTrigger>
        </div>
        {unselectedItems && (
          <ListGroup variant='flush' className='small overflow-auto'>
            {unselectedItems.map((x, i) => (
              <ListGroup.Item
                key={i}
                action
                onClick={(e) => handleSelect(e, [...selectedList, x[by]])}
              >
                {x.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card>
      <Card className='w-50'>
        <Card.Header className='small text-center fw-semibold'>{`Selected ${title}`}</Card.Header>
        <div className='d-flex align-items-center py-1 bg-light'>
          <div className='flex-grow-1 d-flex align-items-center bg-light ms-2 border rounded'>
            <span className='px-2'>
              <i className='bi bi-search' />
            </span>
            <Form.Control
              size='sm'
              className='border-0 rounded-0 bg-light'
              onChange={(e) => handleSelectedSearch(e.target.value)}
            />
          </div>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Unselect All</Tooltip>}
          >
            <Button
              className='py-0 px-2 border-0'
              variant=''
              size='md'
              onClick={(e) => handleSelect(e, [])}
            >
              <i className='bi bi-dash-circle fs-5 text-danger' />
            </Button>
          </OverlayTrigger>
        </div>
        {selectedItems && (
          <ListGroup variant='flush' className='small overflow-auto'>
            {selectedItems.map((x, i) => (
              <ListGroup.Item
                key={i}
                action
                onClick={(e) =>
                  handleSelect(
                    e,
                    selectedList.filter((j) => j !== x[by]),
                  )
                }
              >
                {x.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card>
    </div>
  );
}

export default memo(MultipleSearchSelect);
