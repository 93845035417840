import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner({ text = 'Loading ...' }) {
  return (
    <div className='text-center m-auto p-5'>
      <Spinner variant='primary' style={{ height: '50px', width: '50px' }} />
      <div className='fs-4 text-primary'>{text}</div>
    </div>
  );
}

export default LoadingSpinner;
