import axios from 'axios';
import FileDownload from 'js-file-download';
import { BACKEND_URL } from './api.js';

const GRADE_CACHE_ID = 'grade-cache-id';

function getCacheId(courseId, assignmentId) {
  return GRADE_CACHE_ID + '-' + courseId + '-' + assignmentId;
}

export function getGradeSummary(courseId) {
  return axios.get(BACKEND_URL + '/courses/' + courseId + '/grade', {
    cache: false,
  });
}

export function getGradeStats(courseId, assignmentId) {
  return axios
    .get(
      BACKEND_URL +
        '/courses/' +
        courseId +
        '/grade/' +
        assignmentId +
        '/stats',
    )
    .then((response) => response.data);
}

export function getGrade(courseId, assignmentId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/grade/' + assignmentId, {
      id: getCacheId(courseId, assignmentId),
    })
    .then((response) => {
      if (response.data === '' || response.data === null) return null;
      if (response.data.tasks.run.cmd === null)
        response.data.tasks.run.cmd = '';
      if (response.data.tasks.run.user === null)
        response.data.tasks.run.user = '';
      if (response.data.tasks.run.host === null)
        response.data.tasks.run.host = '';
      if (response.data.tasks.run.path === null)
        response.data.tasks.run.path = '';
      return response.data;
    });
}

export function addGrade(grade, file) {
  if (grade.tasks.run.cmd === '') grade.tasks.run.cmd = null;
  if (grade.tasks.run.user === '') grade.tasks.run.user = null;
  if (grade.tasks.run.host === '') grade.tasks.run.host = null;
  if (grade.tasks.run.path === '') grade.tasks.run.path = null;

  const formData = new FormData();
  formData.append('config', JSON.stringify(grade));
  if (file === null || file instanceof File) formData.append('file', file);
  return axios.post(
    BACKEND_URL +
      '/courses/' +
      grade._id.course_id +
      '/grade/' +
      grade._id.assignment_id,
    formData,
    {
      cache: {
        update: {
          [getCacheId(grade._id.course_id, grade._id.assignment_id)]: (
            gradeCache,
            response,
          ) => {
            if (gradeCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }
            gradeCache.data.data = grade;
            gradeCache.data.data.file = file?.name;
            return gradeCache;
          },
        },
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export function deleteGrade(grade) {
  return axios.delete(
    BACKEND_URL +
      '/courses/' +
      grade._id.course_id +
      '/grade/' +
      grade._id.assignment_id,
    {
      cache: {
        update: {
          [getCacheId(grade._id.course_id, grade._id.assignment_id)]: (
            gradeCache,
            response,
          ) => {
            if (gradeCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }
            const id = gradeCache.data.data._id;
            if (id === grade._id) gradeCache.data.data = null;
            return gradeCache;
          },
        },
      },
    },
  );
}

export function updateGrade(grade, file) {
  if (grade.tasks.run.password === null || grade.tasks.run.password === '')
    delete grade.tasks.run.password;
  if (grade.tasks.run.cmd === '') grade.tasks.run.cmd = null;
  if (grade.tasks.run.user === '') grade.tasks.run.user = null;
  if (grade.tasks.run.host === '') grade.tasks.run.host = null;
  if (grade.tasks.run.path === '') grade.tasks.run.path = null;

  const formData = new FormData();
  formData.append('config', JSON.stringify(grade));
  if (file === null || file instanceof File) formData.append('file', file);

  return axios.put(
    BACKEND_URL +
      '/courses/' +
      grade._id.course_id +
      '/grade/' +
      grade._id.assignment_id,
    formData,
    {
      cache: {
        update: {
          [getCacheId(grade._id.course_id, grade._id.assignment_id)]: (
            gradeCache,
            response,
          ) => {
            if (gradeCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }
            gradeCache.data.data = grade;
            gradeCache.data.data.file = file?.name;
            return gradeCache;
          },
        },
      },
    },
  );
}

export function downloadGrade(courseId, assignmentId) {
  return axios
    .get(
      BACKEND_URL +
        '/courses/' +
        courseId +
        '/grade/' +
        assignmentId +
        '/download',
      { responseType: 'blob' },
    )
    .then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'file';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename=(?:"([^"]+)"|(\S+))/);
        if (match && match[1]) {
          fileName = match[1];
        } else if (match && match[2]) {
          fileName = match[2];
        }
      }
      FileDownload(response.data, fileName);
      return response;
    });
}

export function testSubmission(courseId, assignmentId, file) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(
    BACKEND_URL + '/courses/' + courseId + '/grade/' + assignmentId + '/test',
    formData,
  );
}
