import { memo } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

function Mentions({
  KWS,
  value,
  onChange,
  placeholder = 'Enter body here\nClick on the ? for sample messages and help!\nClick on the eye to visualize the message!',
  singleLine,
  readOnly,
  disabled = false,
}) {
  return (
    <MentionsInput
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={INPUT_STYLE}
      required
      singleLine={singleLine}
      readOnly={readOnly}
      className={'bg-white'}
    >
      <Mention
        trigger={'{{'}
        markup={'\x1E__id__\x1E'}
        displayTransform={(id) => `{{${id}}}`}
        data={KWS}
        style={{ background: 'lightblue' }}
      />
    </MentionsInput>
  );
}

const INPUT_STYLE = {
  '&multiLine': {
    control: {
      minHeight: 100,
    },
    highlighter: {
      padding: 9,
      border: '2px inset transparent',
    },
    input: {
      padding: 9,
      border: '1px solid rgba(0,0,0,0.15)',
      borderRadius: 5,
    },
  },
  '&singleLine': {
    highlighter: {
      padding: 6,
      border: '2px inset transparent',
    },
    input: {
      padding: 6,
      border: '1px solid rgba(0,0,0,0.15)',
      borderRadius: 5,
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export default memo(Mentions);
