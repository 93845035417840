import { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { KW_RE, NUDGE_KWS } from './Constants';

const kw_obj = NUDGE_KWS.reduce((acc, entry) => {
  acc[entry.id] = entry.ie;
  return acc;
}, {});

function Visualize({ title = 'Visualize Message', body }) {
  const [show, setShow] = useState(null);
  const [formatted, setFormatted] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    const replaceMsg = (match, capture) => {
      return capture in kw_obj ? String(kw_obj[capture.toLowerCase()]) : match;
    };
    setFormatted(body.replace(KW_RE, replaceMsg));
    setShow(true);
  };

  return (
    <>
      <i
        className='bi bi-eye-fill text-info'
        onClick={handleShow}
        style={{ cursor: 'pointer' }}
      ></i>
      <Modal show={show} centered size={'lg'} scrollable onHide={handleClose}>
        <Modal.Header
          className='bg-primary text-light'
          closeButton
          closeVariant='white'
        >
          <b>{title}</b>
        </Modal.Header>
        <Modal.Body
          style={{
            whiteSpace: 'pre-line',
            maxHeight: '70vh',
          }}
        >
          {formatted}
        </Modal.Body>
        <Modal.Footer className='bg-light small'>
          <small className=''>
            *These are only placeholder values. The actual keywords will
            coorelate to the students/assignments in the course.
          </small>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(Visualize);
