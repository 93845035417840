import { memo, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { getGroupsForCourse } from '../../api/assignments_api';
import getExtensionsForCourse, {
  addExtension,
  deleteExtension,
  updateExtension,
} from '../../api/extend_api';
import { getAssignmentPage } from '../../util/canvas_url';
import confirmExit from '../../util/confirm_exit';
import Confirm from '../reusable/Confirm';
import Help from '../reusable/Help';
import SearchSelect from '../reusable/SearchSelect';
import * as extendHelp from './ExtendHelp';

function Extend(props) {
  const [extensions, setExtensions] = useState(null);
  const [editing, setEditing] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [assignments, setAssignments] = useState(null);
  const [quizzes, setQuizzes] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);

  function getGroupName(groupId, groups) {
    if (!groups) {
      return '';
    }
    for (const group of groups) {
      if (group.id === groupId) {
        return group.name;
      }
    }
    return '';
  }

  function getAssignments(groups) {
    if (groups === null || props?.assignments === null) {
      return null;
    }
    return props.assignments.filter((assignment) => {
      if (assignment.is_quiz) {
        return false;
      }
      return getGroupName(assignment.group_id, groups)
        .toLowerCase()
        .includes('assignment');
    });
  }

  function getQuizzes(groups) {
    if (groups === null || props?.assignments === null) {
      return null;
    }
    return props.assignments.filter((assignment) => {
      if (!assignment.is_quiz || !assignment.quiz_id) {
        return false;
      }
      return getGroupName(assignment.group_id, groups)
        .toLowerCase()
        .includes('quiz');
    });
  }

  function init_groups(groups) {
    setAssignments(getAssignments(groups));
    setQuizzes(getQuizzes(groups));
  }

  useEffect(() => {
    getGroupsForCourse(props.courseId)
      .then(init_groups)
      .catch(() => toast.error('Failed to load groups'));
  }, [props]);

  useEffect(() => {
    getExtensionsForCourse(props.courseId)
      .then(setExtensions)
      .catch(() => toast.error('Failed to load extensions'));
  }, [props.courseId]);

  confirmExit(
    'You have unsaved changes. Are you sure you want to leave?',
    editing !== null,
  );

  function getName(studentId) {
    if (!props?.students) {
      return '';
    }
    for (const student of props.students) {
      if (student.id === studentId) {
        return student.name;
      }
    }
    return '';
  }

  function getExtensions() {
    return extensions
      ?.map((extension) => ({
        id: extension?.student_id,
        name: getName(extension?.student_id),
        days: extension.days,
        time: extension.time,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  const daysRef = useRef(null);
  const timeRef = useRef(null);

  const [validStudent, setValidStudent] = useState(false);
  const [validDays, setValidDays] = useState(false);
  const [validTime, setValidTime] = useState(false);

  const [invalidStudent, setInvalidStudent] = useState(false);
  const [invalidDays, setInvalidDays] = useState(false);
  const [invalidTime, setInvalidTime] = useState(false);

  function validateNewExtension() {
    const studentId = parseInt(studentInfo.id);
    const days = parseInt(daysRef.current.value);
    const time = parseFloat(timeRef.current.value);

    let valid = true;

    if (isNaN(studentId)) {
      setValidStudent(false);
      setInvalidStudent(true);
      valid = false;
      toast.error('Invalid student name');
    } else {
      setValidStudent(true);
      setInvalidStudent(false);
    }
    if (isNaN(days) || days < 0) {
      setValidDays(false);
      setInvalidDays(true);
      valid = false;
    } else {
      setValidDays(true);
      setInvalidDays(false);
    }
    if (isNaN(time) || time < 1) {
      setValidTime(false);
      setInvalidTime(true);
      valid = false;
    } else {
      setValidTime(true);
      setInvalidTime(false);
    }
    if (days === 0 && time === 1) {
      setValidDays(false);
      setInvalidDays(true);
      setValidTime(false);
      setInvalidTime(true);
      toast.error(
        'Extension must have at least 1 extra day or a time multiplier greater than 1',
      );
      valid = false;
    }

    return [valid, studentId, days, time];
  }

  function onAddExtension() {
    const [valid, studentId, days, time] = validateNewExtension();
    if (!valid) {
      return;
    }
    addExtension(props.courseId, studentId, days, time)
      .then(() => setEditing(null))
      .catch(() => toast.error('Failed to add extension'));
  }

  function onUpdateExtension() {
    const [valid, studentId, days, time] = validateNewExtension(true);
    if (!valid) {
      return;
    }
    updateExtension(props.courseId, studentId, days, time)
      .then(() => setEditing(null))
      .catch(() => toast.error('Failed to update extension'));
  }

  function onStartEditing(index) {
    setValidStudent(false);
    setInvalidStudent(false);
    setValidDays(false);
    setInvalidDays(false);
    setValidTime(false);
    setInvalidTime(false);
    setEditing(index);
  }

  function onDeleteExtension(studentId) {
    setEditing(-1);
    setConfirm(
      <Confirm
        question='Are you sure you want to delete this extension?'
        onConfirm={() => {
          deleteExtension(props.courseId, studentId)
            .then(() => setEditing(null))
            .catch(() => toast.error('Failed to delete extension'));
        }}
        onCancel={() => setEditing(null)}
      />,
    );
  }
  return (
    <div className='d-flex flex-column flex-grow-1 p-3'>
      <Table striped bordered className='mb-0'>
        <thead>
          <tr style={{ verticalAlign: 'middle' }}>
            <th style={{ width: '27%' }}>Student Name</th>
            <th style={{ width: '27%' }}>
              Extra Days for Assignments
              <Help title={'Extra Days'} body={<extendHelp.ExtraDays />} />
            </th>
            <th style={{ width: '27%' }}>
              Time Multiplier for Quizzes
              <Help
                title={'Time Multiplier'}
                body={<extendHelp.TimeMultiplier />}
              />
            </th>
            <th style={{ width: '19%' }}>
              Actions
              <Help title={'Actions'} body={<extendHelp.AutomaticUpdates />} />
            </th>
          </tr>
        </thead>
        <tbody>
          {extensions === null && (
            <tr>
              <td colSpan='100%'>Loading...</td>
            </tr>
          )}
          {extensions !== null && extensions.length === 0 && (
            <tr>
              <td colSpan='100%'>No Extensions in Place</td>
            </tr>
          )}
          {extensions &&
            getExtensions().map((student, index) => {
              if (index === editing) {
                return (
                  <tr key={student.id} className='align-middle'>
                    <td value={student.id}>{student.name}</td>
                    <td>
                      <InputGroup size='sm'>
                        <Form.Control
                          type='number'
                          placeholder='Extra Days for Assignments'
                          defaultValue={student.days}
                          min={0}
                          ref={daysRef}
                          isValid={validDays}
                          isInvalid={invalidDays}
                        />
                        <InputGroup.Text>days</InputGroup.Text>
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup size='sm'>
                        <Form.Control
                          type='number'
                          placeholder='Time Multiplier for Quizzes'
                          step={0.1}
                          defaultValue={student.time}
                          min={1.0}
                          ref={timeRef}
                          isValid={validTime}
                          isInvalid={invalidTime}
                        />
                        <InputGroup.Text>x</InputGroup.Text>
                      </InputGroup>
                    </td>
                    <td className='text-center'>
                      <Button
                        className='me-1 text-light'
                        variant='info'
                        size='sm'
                        onClick={() => {
                          onUpdateExtension();
                        }}
                      >
                        <i className='bi bi-floppy-fill me-1' />
                        Save
                      </Button>
                      <Button
                        className='text-light'
                        variant='danger'
                        size='sm'
                        onClick={() => {
                          setEditing(null);
                        }}
                      >
                        <i className='bi bi-x-circle-fill me-1' />
                        Cancel
                      </Button>
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={student.id} className='align-middle'>
                  <td>{student.name}</td>
                  <td>{student.days} days</td>
                  <td>{student.time}x</td>
                  <td className='text-center'>
                    <Button
                      className='me-1 text-light'
                      variant={editing === null ? 'info' : 'dark'}
                      size='sm'
                      disabled={editing !== null}
                      onClick={() => onStartEditing(index)}
                    >
                      <i className='bi bi-pencil-fill me-1' />
                      Edit
                    </Button>
                    <Button
                      className='text-light'
                      variant={editing === null ? 'danger' : 'dark'}
                      size='sm'
                      disabled={editing !== null}
                      onClick={() => onDeleteExtension(student.id)}
                    >
                      <i className='bi bi-trash-fill me-1' />
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          {(editing === null || editing < extensions?.length) && (
            <tr>
              <td className='text-center' colSpan='100%'>
                <Button
                  variant={editing === null ? 'primary' : 'dark'}
                  disabled={editing !== null}
                  onClick={() => onStartEditing(extensions?.length)}
                  size='lg'
                >
                  <i className='bi bi-plus-circle-fill me-1' />
                  Add Student
                </Button>
              </td>
            </tr>
          )}
          {editing === extensions?.length && (
            <tr>
              <td>
                <SearchSelect
                  setIdName={setStudentInfo}
                  items={props.students}
                  idsToFilter={extensions.map((ext) => ext.student_id)}
                  placeholder={'Student Name'}
                  isValid={validStudent}
                  isInvalid={invalidStudent}
                  required={true}
                />
              </td>
              <td>
                <InputGroup size='sm'>
                  <Form.Control
                    type='number'
                    placeholder='Extra days for assignments'
                    defaultValue={0}
                    min={0}
                    ref={daysRef}
                    isValid={validDays}
                    isInvalid={invalidDays}
                    size='sm'
                  />
                  <InputGroup.Text>days</InputGroup.Text>
                </InputGroup>
              </td>
              <td>
                <InputGroup size='sm'>
                  <Form.Control
                    type='number'
                    placeholder='Time multiplier for quizzes'
                    step={0.1}
                    defaultValue={1.0}
                    min={1.0}
                    ref={timeRef}
                    isValid={validTime}
                    isInvalid={invalidTime}
                    size='sm'
                  />
                  <InputGroup.Text>x</InputGroup.Text>
                </InputGroup>
              </td>
              <td className='text-center'>
                <Button
                  className='me-1 text-light'
                  variant='info'
                  size='sm'
                  onClick={onAddExtension}
                >
                  <i className='bi bi-floppy-fill me-1' />
                  Save
                </Button>
                <Button
                  className='text-light'
                  variant='danger'
                  size='sm'
                  onClick={() => setEditing(null)}
                >
                  <i className='bi bi-x-circle-fill me-1' />
                  Cancel
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className='p-3 text-center'>
        Extend Best Practices
        <Help
          title={'Extend Best Practices'}
          body={<extendHelp.ExtendBestPractices />}
        />
      </div>
      <Row
        xs={1}
        md={2}
        className='justify-content-center text-center m-auto g-5'
      >
        <Col>
          <Table striped bordered>
            <thead>
              <tr>
                <th>
                  Assignments Eligible for Extra Days{' '}
                  <Help
                    title={'Assignment Eligibility for Extra Days'}
                    body={<extendHelp.AssignmentEligibility />}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {assignments &&
                assignments.map((assignment) => (
                  <tr key={assignment.id}>
                    <td>
                      <a
                        className='text-dark'
                        href={getAssignmentPage(props.courseId, assignment.id)}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {assignment.name}
                      </a>
                    </td>
                  </tr>
                ))}
              {assignments !== null && assignments.length === 0 && (
                <tr>
                  <td>No assignments</td>
                </tr>
              )}
              {assignments === null && (
                <tr>
                  <td>Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table striped bordered>
            <thead>
              <tr>
                <th>
                  Quizzes Eligible for Time Multiplier{' '}
                  <Help
                    title={'Quiz Eligibility for Time Multiplier'}
                    body={<extendHelp.QuizEligibility />}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {quizzes &&
                quizzes.map((quiz) => (
                  <tr key={quiz.id}>
                    <td>
                      <a
                        className='text-dark'
                        href={getAssignmentPage(props.courseId, quiz.id)}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {quiz.name}
                      </a>
                    </td>
                  </tr>
                ))}
              {quizzes !== null && quizzes.length === 0 && (
                <tr>
                  <td>No quizzes</td>
                </tr>
              )}
              {quizzes === null && (
                <tr>
                  <td>Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className='flex-grow-1' />
      <Help
        title={'Extend Help'}
        body={<extendHelp.ExtendInfo />}
        label={'Extend Help'}
      />
      {confirm}
    </div>
  );
}

export default memo(Extend);
