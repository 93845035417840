import { memo, useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as grade_api from '../../api/grade_api';
import confirmExit from '../../util/confirm_exit';
import { deepCopy } from '../../util/deep_copy';
import Confirm from '../reusable/Confirm';
import ExpandableCard from '../reusable/ExpandableCard';
import Help from '../reusable/Help';
import * as constants from './Constants';
import GeneralConfig from './GeneralConfig';
import GradeLogs from './GradeLogs';
// import ManualGrading from './ManualGrading';
import Statistics from './Statistics';
import SubmissionTest from './SubmissionTest';

const success_icon = <i className='bi bi-check-circle-fill text-success' />;
const failure_icon = (
  <i className='bi bi-exclamation-circle-fill text-danger' />
);

export function getStatusTD(success) {
  return (
    <td className='fs-5 text-center'>
      {success ? success_icon : failure_icon}
    </td>
  );
}

function AssignmentGrade({
  exists,
  assignmentID,
  assignmentName,
  courseId,
  courseCode,
  students,
  setViewChange,
}) {
  const [config, setConfig] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [logsData, setLogsData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [editState, setEditState] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [validated, setValidated] = useState(false);
  const [validDays, setValidDays] = useState(false);
  const [fixErrorsMsg, setFixErrorsMsg] = useState('');
  const [tasks, setTasks] = useState(null);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();

  confirmExit(
    'You have unsaved changes. Are you sure you want to leave?',
    editState !== null,
  );

  useEffect(() => {
    if (exists) grade_api.getGrade(courseId, assignmentID).then(setConfig);
    else {
      setFormData({
        ...constants.DEFAULT_CONFIG,
        _id: {
          course_id: Number(courseId),
          assignment_id: Number(assignmentID),
        },
      });
      let defTasks = { ...constants.DEFAULT_TASKS };
      const courseVal = nameToPath(courseCode, 17);
      const assignVal = nameToPath(assignmentName, 17);
      defTasks.run.path = `~/ramdesk/${courseVal}/${assignVal}`;
      setTasks({ ...defTasks });
      setEditState(true);
    }
  }, [assignmentID]);

  useEffect(() => {
    if (config !== null) constructFormData();
  }, [config]);

  const constructFormData = (from = config) => {
    const delayNotSchedule = Object.prototype.hasOwnProperty.call(
      from.schedule,
      'delay_minutes',
    );
    let completeData = { ...constants.DEFAULT_CONFIG };

    completeData._id = { ...from._id };
    completeData.active = from.active;
    completeData.networking = from.networking;
    completeData.versions = { ...from.versions };
    completeData.keep_highest = from.keep_highest;

    if (delayNotSchedule) {
      const delay = from.schedule.delay_minutes;
      const hours = Math.floor(delay / 60);
      const mins = delay % 60;

      completeData.config_type = 'delay';
      completeData.delay_minutes.hours = hours;
      completeData.delay_minutes.mins = mins;
    } else {
      completeData.config_type = 'schedule';
      completeData.hour = from.schedule.hour;
      completeData.days = { ...from.schedule.days };
    }
    !from?.file ? setFileData(null) : setFileData({ name: from?.file });
    setFormData(completeData);
    setTasks({ ...from.tasks });
  };

  const constructConfigPayload = () => {
    let payload = {
      _id: formData._id,
      active: formData.active,
      networking: formData.networking,
      versions: formData.versions,
      keep_highest: formData.keep_highest,
      tasks: { ...tasks },
      schedule: {},
    };

    if (formData.config_type === 'delay') {
      const totalMins =
        formData.delay_minutes.hours * 60 + formData.delay_minutes.mins;
      payload.schedule.delay_minutes = totalMins;
    } else if (formData.config_type === 'schedule') {
      payload.schedule.days = { ...formData.days };
      payload.schedule.hour = formData.hour;
    }

    return payload;
  };

  function nameToPath(name, maxLength) {
    return name
      .replace(/\s|-/g, '_')
      .replace(/\W+/g, '')
      .replace(/_+/g, '_')
      .slice(0, maxLength);
  }

  const handleReturnToDashboard = () => {
    setViewChange((prev) => !prev);
    navigate(-1);
  };

  const handleDeleteConfig = () => {
    setShowModal(
      <Confirm
        question='Are you sure you want to delete this autograder?'
        onConfirm={() => {
          grade_api
            .deleteGrade(config)
            .then(() => {
              setFormData(null);
              setTasks(null);
              setConfig(null);
              setFileData(null);
              setShowModal(null);
              handleReturnToDashboard();
              toast.success('Successfully deleted autograder!');
            })
            .catch(() => toast.error('Failed to delete autograder!'));
        }}
        onCancel={() => setShowModal(null)}
      />,
    );
  };

  const reset = () => {
    setEditState(null);
    setValidDays(false);
    setFixErrorsMsg('');
    setValidated(false);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();

    if (!Object.values(formData.days).some((value) => value)) {
      setValidDays(true);
      return;
    }
    if (tasks.run.cmd === '' && tasks.run.type === 'custom') {
      setFixErrorsMsg(`Command for Run Grader must not be empty!`);
      return;
    }

    if (tasks.run.location === 'ssh' && !formData.networking) {
      setFixErrorsMsg(
        'You must first enable networking if you want to use the ssh feature!',
      );
      return;
    }
    if (tasks.run.host === 'localhost' || tasks.run.host === '127.0.0.1') {
      setFixErrorsMsg('Host cannot contain localhost or 127.0.0.1');
      return;
    }

    if (tasks.check_files.enabled) {
      if (
        tasks.check_files.required.length === 0 &&
        tasks.check_files.allowed.length === 0
      ) {
        setFixErrorsMsg(
          'You must enter either allowed or required files when check files is enabled!',
        );
        return;
      }
    }

    if (tasks.check_code.enabled) {
      if (
        tasks.check_code.required.length === 0 &&
        tasks.check_code.forbidden.length === 0
      ) {
        setFixErrorsMsg(
          'You must enter either required or forbidden code when check code is enabled.',
        );
        return;
      }
    }

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      const payload = constructConfigPayload();
      if (config) {
        grade_api
          .updateGrade(deepCopy(payload), fileData)
          .then(() => {
            setConfig({ ...payload, file: fileData?.name });
            reset();
            toast.success('Updated autograder successfully!');
          })
          .catch(() => toast.error('Failed to update autograder!'));
      } else {
        grade_api
          .addGrade(deepCopy(payload), fileData)
          .then(() => {
            setConfig({ ...payload, file: fileData?.name });
            reset();
            toast.success('Added new autograder successfully!');
          })
          .catch(() => toast.error('Failed to add new autograder!'));
      }
    }
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    if (config !== null) {
      constructFormData();
    } else {
      setFormData(null);
      setTasks(null);
      setFileData(null);
      handleReturnToDashboard();
    }
    reset();
  };

  const handleFileDownload = (e) => {
    e.preventDefault();
    grade_api
      .downloadGrade(courseId, assignmentID)
      .catch(() => toast.error('Failed to retrieve the grade file.'));
  };

  async function copyData() {
    try {
      const objToCopy = constructConfigPayload();
      await navigator.clipboard.writeText(JSON.stringify(objToCopy));
      toast.success('Successfully copied grader data to clipboard!');
    } catch (err) {
      toast.error('Something went wrong when copying: ' + err.name);
    }
  }

  async function pasteData() {
    try {
      let pastedData = await navigator.clipboard.readText();
      pastedData = JSON.parse(pastedData);

      if (
        !['_id', 'active', 'schedule', 'networking', 'versions', 'tasks'].every(
          (key) => Object.prototype.hasOwnProperty.call(pastedData, key),
        )
      )
        throw new Error('Failed to paste - all required fields not present!');

      pastedData._id = {
        course_id: Number(courseId),
        assignment_id: Number(assignmentID),
      };

      constructFormData(pastedData);
      toast.success('Successfully populated grade configuration!');
    } catch (err) {
      if (err instanceof SyntaxError)
        toast.error('Failed to paste - invalid data!');
      else toast.error(err.message);
    }
  }

  return (
    <div className='px-3 pb-3'>
      <div
        className='m-4 d-flex'
        style={{ height: '40px', whiteSpace: 'nowrap' }}
      >
        <div
          className='flex-shrink-1 fw-bold bg-primary text-light px-3 d-flex align-items-center border rounded-start-3'
          style={{ cursor: 'pointer' }}
          onClick={handleReturnToDashboard}
        >
          <i className='bi bi-arrow-return-left me-2' />
          Go Back
        </div>
        <div className='flex-grow-1 bg-white fw-bold text-primary d-flex align-items-center border-top border-bottom px-2'>
          {assignmentName}
        </div>
        <div className='bg-white d-flex align-items-center border rounded-end-3'>
          <Help
            title='Grade Help'
            body={<constants.GradeHelp />}
            iconSize='fs-4'
          />
        </div>
      </div>
      {config !== null && (
        <ExpandableCard open={true}>
          <div className='flex-grow-1'>Logs</div>
          <div className='m-1'>
            <GradeLogs
              courseId={courseId}
              assignmentId={assignmentID}
              students={students}
              logsData={logsData}
              setLogsData={setLogsData}
            />
          </div>
        </ExpandableCard>
      )}
      {formData && (
        <ExpandableCard open={editState === true}>
          <>
            <div className='flex-grow-1'>Configuration</div>
            {formData.active ? (
              <div>
                Active
                <i className='bi bi-check-circle-fill ms-2'></i>
              </div>
            ) : (
              <div>
                Inactive
                <i className='bi bi-exclamation-circle-fill ms-2'></i>
              </div>
            )}
          </>
          <Form
            onSubmit={handleSaveEdit}
            noValidate
            validated={validated}
            className='p-3'
          >
            <GeneralConfig
              formData={formData}
              setFormData={setFormData}
              tasks={tasks}
              setTasks={setTasks}
              editState={editState}
              fileData={fileData}
              setFileData={setFileData}
              validDays={validDays}
              handleFileDownload={handleFileDownload}
            />
            <br />
            <div className='text-end border-top pt-2'>
              <span className='text-danger small'>{fixErrorsMsg}</span>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>Copy Grade Configuration to Clipboard</Tooltip>
                }
              >
                <Button
                  className='mx-1'
                  variant='light'
                  size='md'
                  onClick={() => copyData()}
                >
                  <i className='bi bi-copy'></i>
                </Button>
              </OverlayTrigger>
              {editState === null ? (
                <>
                  <Button
                    className='mx-1 text-light'
                    variant='info'
                    size='md'
                    onClick={() => setEditState(true)}
                  >
                    <i className='bi bi-pencil-fill'></i> Edit
                  </Button>
                  <Button
                    className='mx-1 text-light'
                    variant='danger'
                    size='md'
                    onClick={handleDeleteConfig}
                  >
                    <i className='bi bi-trash-fill'></i> Delete
                  </Button>
                </>
              ) : (
                <>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        Paste Grade Configuration from Clipboard
                      </Tooltip>
                    }
                  >
                    <Button
                      className='mx-1'
                      variant='light'
                      size='md'
                      onClick={() => pasteData()}
                    >
                      <i className='bi bi-clipboard'></i>
                    </Button>
                  </OverlayTrigger>
                  <Button
                    className='mx-1 text-light'
                    variant='info'
                    size='md'
                    type={'submit'}
                  >
                    <i className='bi bi-floppy-fill'></i> Save
                  </Button>
                  <Button
                    className='mx-1 text-light'
                    variant='danger'
                    size='md'
                    onClick={handleCancelEdit}
                  >
                    <i className='bi bi-ban'></i> Cancel
                  </Button>
                </>
              )}
            </div>
          </Form>
        </ExpandableCard>
      )}
      {config !== null && (
        <>
          <ExpandableCard>
            <div className='flex-grow-1'>Submission Test</div>
            <div>
              <SubmissionTest courseId={courseId} assignmentId={assignmentID} />
            </div>
          </ExpandableCard>
          {/* <ExpandableCard>
            <div className='flex-grow-1'>Manual Grading</div>
            <div>
              <ManualGrading activeState={config.active} students={students} />
            </div>
          </ExpandableCard> */}
          <ExpandableCard
            onClickHeader={() =>
              grade_api.getGradeStats(courseId, assignmentID).then(setStats)
            }
          >
            <div className='flex-grow-1'>Statistics</div>
            <div>
              <Statistics data={stats} />
            </div>
          </ExpandableCard>
        </>
      )}
      {showModal}
    </div>
  );
}

export default memo(AssignmentGrade);
