import axios from 'axios';
import { BACKEND_URL } from './api';

export function updateUserSubscription(token) {
  return axios.get(BACKEND_URL + '/unsubscribe/' + token, { cache: false });
}

export function reportProblem(formData) {
  return axios.post(BACKEND_URL + '/bugreport', formData);
}
