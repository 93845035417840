import { memo, useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import getRules, { deleteRule } from '../../api/nudge_api';
import confirmExit from '../../util/confirm_exit';
import Help from '../reusable/Help';
import { DEFAULT_RULE, NudgeGeneralHelp } from './Constants';
import RuleCard from './RuleCard';

function Nudge({ courseId, students, assignments }) {
  const [rules, setRules] = useState([]);
  const [activeRule, setActiveRule] = useState(null);
  const [editState, setEditState] = useState(null);

  confirmExit(
    'You have unsaved changes. Are you sure you want to leave?',
    editState !== null,
  );

  useEffect(() => {
    getRules(courseId).then(setRules);
  }, []);

  useEffect(() => {
    if (rules.length > 0 && rules[0]._id === 0) {
      window.scrollTo(0, 0);
      setActiveRule(0);
      setEditState(0);
    }
  }, [rules]);

  const handleAddRule = (rule = DEFAULT_RULE) => {
    const newRules = [{ ...rule, _id: 0, course_id: courseId }, ...rules];
    setRules(newRules);
    setActiveRule(null);
  };

  const handleDeleteRule = (ruleID) => {
    const removeRuleFromList = () =>
      setRules(rules.filter((item) => item._id !== ruleID));

    if (ruleID === 0) {
      removeRuleFromList();
    } else {
      deleteRule(courseId, ruleID)
        .then(() => {
          removeRuleFromList();
          toast.success('Successfully deleted rule');
        })
        .catch(() => toast.error('Failed to delete rule'));
    }
  };

  return (
    <div className='d-flex flex-column p-3 flex-grow-1'>
      {rules.length > 0 && (
        <Accordion key={activeRule} defaultActiveKey={activeRule}>
          {rules.map((item) => (
            <RuleCard
              key={item._id}
              entry={item}
              deleteRule={handleDeleteRule}
              dupRule={handleAddRule}
              setRules={setRules}
              setEditState={setEditState}
              editState={editState}
              students={students}
              assignments={assignments && assignments.filter((a) => !a.is_quiz)}
            />
          ))}
        </Accordion>
      )}
      {editState === null && (
        <Button
          variant='primary'
          style={{ width: 'fit-content' }}
          className='align-self-center mt-4'
          onClick={() => handleAddRule()}
          size='lg'
        >
          <i className='bi bi-plus-circle-fill' /> Add Rule
        </Button>
      )}
      <div className='flex-grow-1' />
      <Help
        title={'Nudge Help'}
        body={<NudgeGeneralHelp />}
        label={'Nudge Help'}
      />
    </div>
  );
}
export default memo(Nudge);
