const DEFAULT_RULE = {
  name: 'New Rule',
  active: false,
  allowUnsubscribe: false,
  dueNotLate: true,
  unsubmitted: false,
  thresholdExists: false,
  daysBeforeSubmission: 5,
  gradeThreshold: 70,
  assignmentsToInclude: [],
  studentsToInclude: [],
  daysToSendEmails: {
    0: false,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: false,
  },
  hourToSendEmails: 9,
  subject: '',
  btBody: '',
  unsubBody: '',
  genBody: '',
};

const NUDGE_KWS = [
  {
    id: 'student.name',
    display: '{{student.name}}',
    desc: 'The name of the student is substituted here',
    ie: 'John Doe',
  },
  {
    id: 'student.grade',
    display: '{{student.grade}}',
    desc: 'The grade of the student for an assignment',
    ie: '50',
  },
  {
    id: 'assignment.name',
    display: '{{assignment.name}}',
    desc: 'The name of the current assignment',
    ie: 'Assignment 1: Some Assignment Name',
  },
  {
    id: 'assignment.date',
    display: '{{assignment.date}}',
    desc: 'The due date of the assignment; both normal and late deadlines use the same keyword',
    ie: 'September 20, 2024 06:00:00 PM MDT',
  },
  {
    id: 'assignment.points',
    display: '{{assignment.points}}',
    desc: 'The number of points the assignment is worth',
    ie: '10',
  },
  {
    id: 'assignment.daysleft',
    display: '{{assignment.daysleft}}',
    desc: 'The number of days remaining from when the message is sent to the due/late date of the assignment',
    ie: '1',
  },
  {
    id: 'assignment.attempts',
    display: '{{assignment.attempts}}',
    desc: 'The number of attempts the student has remaining on the assignment. If unlimited, returns "unlimited", else returns a number',
    ie: 'unlimited',
  },
  {
    id: 'course.code',
    display: '{{course.code}}',
    desc: 'The short name of the course',
    ie: '2024SP-CS-100-001',
  },
  {
    id: 'course.name',
    display: '{{course.name}}',
    desc: 'The full name of the course',
    ie: '2024SM-CS-100-001: Introduction to C++',
  },
  {
    id: 'threshold',
    display: '{{threshold}}',
    desc: 'The grade threshold set in the form',
    ie: '70',
  },
  {
    id: 'late.interval',
    display: '{{late.interval}}',
    desc: 'When the late penalty is applied; could be "hour" or "day"',
    ie: 'day',
  },
  {
    id: 'late.deduction',
    display: '{{late.deduction}}',
    desc: 'How much the penalty for late submissions is per interval in percent',
    ie: '15',
  },
  {
    id: 'possible.grade',
    display: '{{possible.grade}}',
    desc: 'What the max achievable score is accounting for late penalty and interval if late is chosen; if considering regular due dates or no late policy exists, this is 100%',
    ie: '100',
  },
];

const KW_RE = /\x1E([^\x1E]*?)\x1E/gi;

const SAMPLE_SUBJECT_1 = `\x1Ecourse.code\x1E Reminder for \x1Eassignment.name\x1E`;
const SAMPLE_SUBJECT_2 = `You can still get \x1Epossible.grade\x1E% on \x1Eassignment.name\x1E!`;

const SAMPLE_EXAM = `Dear \x1Estudent.name\x1E,\n
\x1Eassignment.name\x1E is due on \x1Eassignment.date\x1E. The exam will be administered using Respondus and you have the entire day to complete it, but please do not procrastinate.\n
The materials being covered on this exam are ... and it will be worth \x1Eassignment.points\x1E points.\n
If the average of the students for this exam is less than 80%, the exam will be curved to ensure that the average is 80%.\n
Best,
The \x1Ecourse.code\x1E Team`;

const SAMPLE_BT = `Dear \x1Estudent.name\x1E,\n
You are receiving this reminder because you received a grade of \x1Estudent.grade\x1E for "\x1Eassignment.name\x1E". We would like to see your score be above \x1Ethreshold\x1E. You can still submit the assignment until \x1Eassignment.date\x1E to get full points.\n
We strongly encourage that you do these submissions early and often. It often takes students multiple attempts to hone their submissions for a full score. It is seldom the case that the student gets a full score on the first attempt. Students who have submitted multiple times typically have a full score on the assignment.\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_UNSUB = `Hi \x1Estudent.name\x1E,\n
You are receiving this reminder because you do not have any submissions for "\x1Eassignment.name\x1E". You can submit the assignment until \x1Eassignment.date\x1E to get full points.\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_LATE_BT = `Dear \x1Estudent.name\x1E,\n
There are only \x1Eassignment.daysleft\x1E day(s) left until the late submission deadline for \x1Eassignment.name\x1E!\n
You are receiving this reminder because you can still improve your grade before the deadline as you still have \x1Eassignment.attempts\x1E attempt(s). Though the submission deadline has passed, if you submit now and get a full score, you can still get a \x1Epossible.grade\x1E%!\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_LATE_UNSUB = `Good Morning \x1Estudent.name\x1E,\n
Only \x1Eassignment.daysleft\x1E day(s) left until the late submission deadline for \x1Eassignment.name\x1E!\n
You are receiving this reminder because you do not have any submissions for "\x1Eassignment.name\x1E". Though the submission deadline has passed, you can still submit the assignment until the late deadline \x1Eassignment.date\x1E to get partial points (-\x1Elate.deduction\x1E% for each \x1Elate.interval\x1E starting from the regular submission date).\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const NudgeGeneralHelp = () => {
  return (
    <div className='small'>
      <p>
        Using Nudge, you can define &#39;rules&#39; for assignments (not
        quizzes) for sending reminders/messages to students included in the
        rule. These can be set to target assignments x number of days before the
        due date or late date. Additionally, you have the ability to target only
        the students who have not submitted anything, the students who received
        a grade below a threshold you set, both groups, or neither. Depending on
        the choice, you can define your custom message in the message boxes. You
        are also able to change the days on which the messages are sent, the
        hour, the students to include, and the assignments to include, the
        latter two of which are all by default if no students/assignments are
        selected.
      </p>
      <b>8 Types of Configurations Can be Defined by One Rule:</b>
      <ul>
        <li>
          <b>Due Date, Unsubmitted, Less than x% </b>
          For the assignments selected, the selected students will receive a
          message if they have not submitted anything or have a grade below the
          threshold on the set days at the set hour if those assignments fall
          within the x number of days before the submission and the due date.
        </li>
        <li>
          <b>Due Date, Unsubmitted </b>
          For the assignments selected, the selected students will receive a
          message only if they have not submitted anything on the set days at
          the set hour if those assignments fall within the x number of days
          before the submission and the due date.
        </li>
        <li>
          <b>Due Date, Less than x% </b>
          For the assignments selected, the selected students will receive a
          message only if they have received a grade less than the set threshold
          on the set days at the set hour if those assignments fall within the x
          number of days before the submission and the due date.
        </li>
        <li>
          <b>Due Date, Neither </b>
          For the assignments selected, the selected students will receive a
          message regardless of if they submitted anything or have a grade above
          the threshold (in other words, all students) on the set days at the
          set hour if those assignments fall within the x number of days before
          the submission and the due date.
        </li>
        <li>
          <b>Late Date, Unsubmitted, Less than x% </b>
          For the assignments selected, the selected students will receive a
          message if they have not submitted anything or have a grade below the
          threshold on the set days at the set hour if those assignments fall
          within the due date and the late date.
        </li>
        <li>
          <b>Late Date, Unsubmitted </b>
          For the assignments selected, the selected students will receive a
          message only if they have not submitted anything on the set days at
          the set hour if those assignments fall within the due date and the
          late date.
        </li>
        <li>
          <b>Late Date, Less than x% </b>
          For the assignments selected, the selected students will receive a
          message only if they have received a grade less than the set threshold
          on the set days at the set hour if those assignments fall within the
          due date and the late date.
        </li>
        <li>
          <b>Late Date, Neither </b>
          For the assignments selected, the selected students will receive a
          message regardless of if they submitted anything or have a grade above
          the threshold (in other words, all students) on the set days at the
          set hour if those assignments fall within within the due date and the
          late date.
        </li>
      </ul>
      <p>
        Having more rules allows for more combinations, for example a rule for
        due dates for both types and another rule for late dates for both types
        allows you to define messages for all four scenarios and is recommended.
      </p>
      <b>Emails</b>
      <p>
        The emails will be sent from the ramdesk account, with the email
        &#39;compsci_ramdesk_noreply@colostate.edu&#39;. If a student is
        included in multiple rules or has multiple assignments where they are
        targeted by the rule, they could receive a lot of emails from ramdesk.
        Therefore, configuring the days before submission, the assignments
        included, and the number of rules would be best.
      </p>
    </div>
  );
};

export {
  DEFAULT_RULE,
  NUDGE_KWS,
  KW_RE,
  SAMPLE_SUBJECT_1,
  SAMPLE_SUBJECT_2,
  SAMPLE_EXAM,
  SAMPLE_BT,
  SAMPLE_LATE_BT,
  SAMPLE_UNSUB,
  SAMPLE_LATE_UNSUB,
  NudgeGeneralHelp,
};
