import { memo, useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';

function AdvancedTable({
  columns,
  data,
  makeRow,
  makeEditRow = null,
  onDelete = null,
  onSave = null,
  onCancel = null,
  striped = false,
  bordered = false,
  hover = false,
  responsive = false,
  className = '',
  actionsWidth = '15%',
  onClickRow = () => null,
}) {
  const [editing, setEditing] = useState(null);
  const [sorted, setSorted] = useState({ column: null, direction: null });
  const [searched, setSearched] = useState({ column: null, value: null });
  const [processedData, setProcessedData] = useState(data);
  const searchRef = useRef(null);

  useEffect(() => {
    searchRef?.current?.focus();
  }, [searched, searchRef]);

  const searchedRef = useRef(searched);
  useEffect(() => {
    searchedRef.current = searched;
  }, [searched]);

  function processData() {
    setEditing(null);
    let result = [...data];
    if (
      searched.column !== null &&
      searched.value !== null &&
      searched.value !== ''
    ) {
      result = result.filter((row) =>
        row[searched.column]
          .toString()
          .toLowerCase()
          .includes(searched.value.toLowerCase()),
      );
    }
    if (sorted.column !== null && sorted.direction !== null) {
      result = result.sort((a, b) => {
        const aVal = a[sorted.column];
        const bVal = b[sorted.column];

        if (aVal === null || bVal === null) return -1;
        let compVal = 0;
        switch (typeof aVal) {
          case 'boolean':
            compVal = aVal === bVal ? 0 : aVal ? 1 : -1;
            break;
          case 'number':
            compVal = aVal - bVal;
            break;
          case 'string':
            compVal = aVal.localeCompare(bVal);
            break;
          default:
            if (aVal instanceof Date) compVal = aVal - bVal;
            else compVal = String(aVal).localeCompare(String(bVal));
        }

        return sorted.direction === 'asc' ? compVal : -compVal;
      });
    }
    setProcessedData(result);
  }

  useEffect(processData, [sorted, searched, data]);

  function makeSortIcon(column) {
    if (column?.sortable) {
      const icon =
        sorted.column === column.id
          ? sorted.direction === 'asc'
            ? 'bi-sort-down-alt'
            : 'bi-sort-up'
          : 'bi-arrow-down-up';
      function onClick() {
        if (sorted.column === column.id) {
          if (sorted.direction === 'asc') {
            setSorted({ column: column.id, direction: 'desc' });
          } else if (sorted.direction === 'desc') {
            setSorted({ column: null, direction: null });
          }
        } else {
          setSorted({ column: column.id, direction: 'asc' });
        }
      }
      return (
        <i
          className={`bi ${icon} pe-2`}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    return null;
  }

  function makeSearchIcon(column) {
    if (column?.searchable) {
      function onClick() {
        setSearched({ column: column.id, value: '' });
      }
      return (
        <i
          className='bi bi-search ps-2'
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    return null;
  }

  function makeTH(column) {
    if (searched.column === column.id) {
      function onChange(e) {
        setSearched({ column: column.id, value: e.target.value });
      }
      function onBlur() {
        setTimeout(() => {
          if (
            searchedRef.current.column === column.id &&
            searchedRef.current.value === ''
          ) {
            setSearched({ column: null, value: null });
          }
        }, 100);
      }
      return (
        <th
          key={column.id}
          width={column?.width}
          className={`py-0 vertical-middle bg-light ${column?.headerStyle}`}
        >
          <Form.Control
            size='sm'
            className='py-0 m-0'
            type='text'
            value={searched.value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={column.value}
            ref={searchRef}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setSearched({ column: null, value: null });
              }
            }}
          />
        </th>
      );
    }
    return (
      <th
        className={`py-2 vertical-middle bg-light ${column?.headerStyle}`}
        key={column.id}
        width={column?.width}
        style={{ userSelect: 'none', whiteSpace: 'nowrap' }}
      >
        {makeSortIcon(column)}
        <b>{column.value}</b>
        {makeSearchIcon(column)}
      </th>
    );
  }

  function makeTR(row, index) {
    return (
      <tr
        key={index}
        onClick={() => onClickRow(row.id)}
        style={{
          whiteSpace: 'nowrap',
          cursor: onClickRow !== null ? 'pointer' : 'default',
        }}
      >
        {editing === index ? makeEditRow(row) : makeRow(row)}
        {(onDelete !== null || makeEditRow !== null) && (
          <td className='text-center'>
            <div className='d-inline-flex'>
              {makeEditRow !== null &&
                editing !== index &&
                row?.__no_edit !== true && (
                  <Button
                    className='text-light mx-1 d-inline-flex'
                    variant={editing === null ? 'info' : 'dark'}
                    size='sm'
                    disabled={editing !== null}
                    onClick={() => {
                      setEditing(index);
                    }}
                  >
                    <i className='bi bi-pencil-fill me-1' />
                    Edit
                  </Button>
                )}
              {onDelete !== null && editing !== index && (
                <Button
                  className='text-light mx-1 d-inline-flex'
                  variant={editing === null ? 'danger' : 'dark'}
                  size='sm'
                  disabled={editing !== null}
                  onClick={() => {
                    onDelete(row);
                  }}
                >
                  <i className='bi bi-trash-fill me-1' />
                  Delete
                </Button>
              )}
              {makeEditRow !== null && editing === index && (
                <>
                  <Button
                    className='text-light mx-1 d-inline-flex'
                    variant='info'
                    size='sm'
                    onClick={() => {
                      setEditing(null);
                      onSave(row);
                    }}
                  >
                    <i className='bi bi-floppy-fill me-1' />
                    Save
                  </Button>
                  <Button
                    className='text-light mx-1 d-inline-flex'
                    variant='danger'
                    size='sm'
                    onClick={() => {
                      setEditing(null);
                      onCancel(row);
                    }}
                  >
                    <i className='bi bi-floppy-fill me-1' />
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </td>
        )}
      </tr>
    );
  }

  return (
    <Table
      striped={striped}
      bordered={bordered}
      hover={hover}
      responsive={responsive}
      className={className}
      style={{ tableLayout: 'auto' }}
    >
      <thead>
        <tr>
          {columns.map(makeTH)}
          {(onDelete !== null || makeEditRow !== null) && (
            <th width={actionsWidth} className='bg-light'>
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>{processedData.map((row, index) => makeTR(row, index))}</tbody>
    </Table>
  );
}

export default memo(AdvancedTable);
