import axios from 'axios';
import { BACKEND_URL } from './api.js';

export function getExtendDashboard() {
  return axios
    .get(BACKEND_URL + '/admin/dashboard/extend')
    .then((response) => response?.data);
}

export function getNudgeDashboard() {
  return axios
    .get(BACKEND_URL + '/admin/dashboard/nudge')
    .then((response) => response?.data);
}

export function getGradeDashboard() {
  return axios
    .get(BACKEND_URL + '/admin/dashboard/grade')
    .then((response) => response?.data);
}

export function updateUsers() {
  return axios.put(BACKEND_URL + '/admin/courses');
}
