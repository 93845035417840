import React, { memo, useEffect, useState } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Report from '../interface/Report';
import UserUpdate from './UserUpdate';

const titles = [
  { re: /^\/admin\/users$/, title: 'Users' },
  { re: /^\/admin\/dashboard.*$/, title: 'Dashboard' },
  { re: /^\/courses$/, title: 'Courses' },
  { re: /^\/courses\/[0-9]+$/, title: 'Course' },
  { re: /^\/courses\/[0-9]+\/extend.*$/, title: 'Extend' },
  { re: /^\/courses\/[0-9]+\/nudge.*$/, title: 'Nudge' },
  { re: /^\/courses\/[0-9]+\/grade.*$/, title: 'Grade' },
];

function Header({ name, picture, role, setNavIsHidden }) {
  const [title, setTitle] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    for (const title of titles) {
      if (path.match(title.re)) {
        setTitle(title.title);
        document.title = `${title.title} - RamDesk`;
        return;
      }
    }
    setTitle(null);
    document.title = 'RamDesk';
  }, [location]);

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <Button
      className='d-flex align-items-center mx-2'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Image
        src={picture}
        roundedCircle
        className='border border-2 border-light'
        height={'35px'}
      />
      <span className='small ms-2 d-none d-md-block'>{name}</span>
    </Button>
  ));
  CustomToggle.displayName = 'imageToggle';

  return (
    <div
      className='py-2 d-flex bg-primary align-items-center border-bottom border-3 border-secondary'
      style={{ height: '60px' }}
    >
      <div className='d-flex flex-grow-1 align-items-center text-light ps-3'>
        {name && (
          <Button
            className='me-1 p-0'
            onClick={() => setNavIsHidden((prev) => !prev)}
          >
            <i className='bi bi-list fs-4' />
          </Button>
        )}
        <img src='/ram.svg' height={'48px'} />
        <a
          href='https://www.colostate.edu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='/csu_short.svg'
            className='d-none d-sm-block d-lg-none ms-1'
            height={'48px'}
          />
        </a>
        <a
          href='https://www.colostate.edu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='/csu_long.svg'
            className='d-none d-lg-block ms-1'
            height={'48px'}
          />
        </a>
        <NavLink
          className='link-light border-start border-2 py-2 ps-2 ms-2 fw-bold'
          to={name ? '/courses' : '/login'}
        >
          RAMDESK
        </NavLink>
        {name && (
          <>
            <i className='bi bi-chevron-right mx-2 small' />
            <span>{title}</span>
          </>
        )}
      </div>
      {name && picture && (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            <Dropdown.Item className='small' disabled as={'div'}>
              {role.toUpperCase()}
            </Dropdown.Item>
            {(role === 'superuser' || role === 'admin') && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  className='small'
                  as={'button'}
                  onClick={() => navigate('/admin/dashboard/extend')}
                >
                  <i className='bi bi-clipboard-data-fill' />
                  <span className='ms-2'>Dashboard</span>
                </Dropdown.Item>
                {role === 'admin' && (
                  <>
                    <Dropdown.Item
                      className='small'
                      as={'button'}
                      onClick={() => navigate('/admin/users')}
                    >
                      <i className='bi bi-people-fill' />
                      <span className='ms-2'>Users</span>
                    </Dropdown.Item>
                    <UserUpdate />
                  </>
                )}
              </>
            )}
            <Dropdown.Divider />
            <Report name={name} />
            <Dropdown.Item
              className='small'
              as={'button'}
              onClick={() => navigate('/logout')}
            >
              <i className='bi bi-box-arrow-right' />
              <span className='ms-2'>Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default memo(Header);
