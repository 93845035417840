export function calculateMean(arr) {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
}

export function calculateMedian(arr) {
  const mid = Math.floor(arr.length / 2);
  return arr.length % 2 === 0 ? (arr[mid - 1] + arr[mid]) / 2 : arr[mid];
}

export function calculateMinimum(arr) {
  return Math.min(...arr);
}

export function calculateMaximum(arr) {
  return Math.max(...arr);
}

export function calculateQuartile(arr, q) {
  const pos = (arr.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (arr[base + 1] !== undefined) {
    return arr[base] + rest * (arr[base + 1] - arr[base]);
  } else {
    return arr[base];
  }
}

export function calculateVariance(arr) {
  return (
    arr.reduce((acc, val) => acc + Math.pow(val - calculateMean(arr), 2), 0) /
    (arr.length - 1)
  );
}

export function calculateStd(arr) {
  return Math.sqrt(calculateVariance(arr));
}
