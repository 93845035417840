import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateUserSubscription } from '../../api/misc_api';

function Unsubscribe() {
  const [unsubMsg, setUnsubMsg] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    updateUserSubscription(token)
      .then(() => setUnsubMsg('You have been unsubscribed successfully!'))
      .catch(() => setUnsubMsg('Could not unsubscribe user with given token!'));
  }, []);

  return (
    <div
      className='d-flex flex-column'
      style={{ marginTop: '10%', alignItems: 'center' }}
    >
      <h1 className='fs-2 text-primary border-bottom border-1 border-secondary fw-bolder'>
        {unsubMsg}
      </h1>
    </div>
  );
}

export default Unsubscribe;
