import { useNavigate } from 'react-router-dom';
import '../../../index.scss';
import { memo, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import login from '../../api/authentication_api';
import Help from '../reusable/Help';
import Stats from './Stats';

const CANVAS_PROFILE_SETTINGS =
  'https://colostate.instructure.com/profile/settings';

function Login({ name, setName, setPicture, setRole, setId }) {
  const tokenRef = useRef(null);
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [passType, setPassType] = useState('password');

  useEffect(() => {
    if (name) {
      navigate('/courses', { replace: true });
    }
  }, [name]);

  function onClick() {
    const token = tokenRef.current.value;
    login(token)
      .then((data) => {
        setName(data.name);
        setPicture(data.picture);
        setRole(data.role);
        setId(data.id);
        navigate('/courses');
      })
      .catch((error) => {
        if (error?.response?.data) {
          setErrMsg(error.response.data);
        } else {
          setErrMsg('An error occurred. Please try again.');
        }
      });
  }

  function TokenHelp() {
    return (
      <div>
        <p>
          You will need a token to access RamDesk. To generate a token for your
          account, follow these steps:
        </p>
        <ol>
          <li>
            Navigate to settings in Canvas using this&nbsp;
            <a
              href={CANVAS_PROFILE_SETTINGS}
              target='_blank'
              rel='noopener noreferrer'
            >
              link
            </a>
            &nbsp;for easy access.
          </li>
          <li>
            Under &quot;Approved Integrations&quot;, click on the button to add
            a new access token.
          </li>
          <li>
            Follow directions to generate a new token. We recommend you use
            &quot;RamDesk&quot; for the purpose and leave the expires field
            blank.
          </li>
          <li>
            On the &quot;Access Tken Details&quot; page, copy the token and
            securely store it for future use as you will not be able to see it
            again after leaving the page.
          </li>
        </ol>
      </div>
    );
  }

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center'>
      <div className='text-center pt-5 mt-5'>
        <h1 className='text-primary' style={{ fontSize: '80px' }}>
          <b>RamDesk</b>
        </h1>
        A suite of tools for managing your Canvas courses.
        <hr className='text-secondary border-2' style={{ opacity: 0.8 }} />
        <InputGroup className='mb-2'>
          <Form.Control
            type={passType}
            placeholder='Enter token here'
            ref={tokenRef}
          />
          <InputGroup.Text>
            <i
              className={`bi bi-eye-${passType === 'password' ? '' : 'slash-'}fill`}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                tokenRef.current.type === 'password'
                  ? setPassType('text')
                  : setPassType('password')
              }
            />
          </InputGroup.Text>
          <InputGroup.Text>
            <Help title='Token Help' body={<TokenHelp />} margin='' />
          </InputGroup.Text>
        </InputGroup>
        <div className='text-danger small mb-4'>{errMsg}</div>
        <Button variant='primary' onClick={onClick}>
          Login
        </Button>
      </div>
      <Stats />
    </div>
  );
}

export default memo(Login);
