import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/authentication_api';
import { clearCache } from '../../util/cache';
import { resetTabId } from './Interceptor';

function Logout({ setName, setPicture, setRole }) {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    setName(null);
    setPicture(null);
    setRole(null);
    clearCache();
    resetTabId();
    navigate('/login');
  }, [setName, setPicture]);
  return null;
}

export default memo(Logout);
