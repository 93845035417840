import axios from 'axios';
import { BACKEND_URL } from './api';

const RULES_CACHE_ID = 'nudge-cache-id';

function getCacheId(courseId) {
  return RULES_CACHE_ID + '-' + courseId;
}

export default function getRules(courseID) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseID + '/nudge', {
      id: getCacheId(courseID),
    })
    .then((response) => response.data);
}

export function addRule(courseID, newRule) {
  return axios
    .post(BACKEND_URL + '/courses/' + courseID + '/nudge', newRule, {
      cache: {
        update: {
          [getCacheId(courseID)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }
            newRule._id = response.data.rule_id;
            rulesCache.data.data.push(newRule);
            return rulesCache;
          },
        },
      },
    })
    .then((response) => response.data);
}

export function updateRule(courseID, ruleID, editedRule) {
  return axios.put(
    BACKEND_URL + '/courses/' + courseID + '/nudge/' + ruleID,
    editedRule,
    {
      cache: {
        update: {
          [getCacheId(courseID)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }

            let c = 0;
            for (const rule of rulesCache.data.data) {
              if (rule?._id === ruleID) break;
              c += 1;
            }
            rulesCache.data.data[c] = editedRule;
            return rulesCache;
          },
        },
      },
    },
  );
}

export function deleteRule(courseID, ruleID) {
  return axios.delete(
    BACKEND_URL + '/courses/' + courseID + '/nudge/' + ruleID,
    {
      cache: {
        update: {
          [getCacheId(courseID)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }

            const data = rulesCache.data.data;
            for (let i = data.length - 1; i >= 0; i--) {
              if (data[i]?._id === ruleID) data.splice(i, 1);
            }
            return rulesCache;
          },
        },
      },
    },
  );
}
