const CANVAS_URL = 'https://colostate.instructure.com/';

export function getCoursePage(courseId) {
  if (!courseId) return null;
  return `${CANVAS_URL}/courses/${courseId}`;
}

export function getAssignmentPage(courseId, assignmentId) {
  if (!courseId || !assignmentId) return null;
  return `${CANVAS_URL}/courses/${courseId}/assignments/${assignmentId}`;
}

export function getUserPage(courseId, userId) {
  if (!courseId || !userId) return null;
  return `${CANVAS_URL}/courses/${courseId}/users/${userId}`;
}

export function getAssignmentsPage(courseId) {
  if (!courseId) return null;
  return `${CANVAS_URL}/courses/${courseId}/assignments`;
}
