import { memo, useEffect, useState } from 'react';
import getCourses from '../../api/courses_api';
import LoadingSpinner from '../reusable/LoadingSpinner';
import Sidebar from '../reusable/Sidebar';
import Course from './Course';
import Stats from './Stats';

function Courses() {
  const firstItem = { name: 'Courses', path: '', element: <Stats /> };
  const [items, setItems] = useState([firstItem]);
  const [spinner, setSpinner] = useState(true);

  function createItems(data) {
    if (!Array.isArray(data)) return [];
    let items = data.map((course) => ({
      name: course.name,
      path: course.id.toString(),
      element: <Course courseInfo={course} enrollType={course.enroll_type} />,
      tooltip: true,
    }));
    items.unshift(firstItem);
    return items;
  }

  useEffect(() => {
    setSpinner(true);
    getCourses()
      .then((data) => {
        setItems(createItems(data));
        setSpinner(false);
      })
      .catch(() => null);
  }, []);

  return spinner ? (
    <LoadingSpinner text='Loading Courses...' />
  ) : (
    <Sidebar items={items} />
  );
}

export default memo(Courses);
