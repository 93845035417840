export function ExtendInfo() {
  return (
    <p className='small'>
      Extend is used to give students recurring accomodations on assignments and
      quizzes. These accomodations are applied to all past, present, and future
      assignments and quizzes of a course. If you want to give a student a
      one-time accomodation, you should do that on Canvas instead.
    </p>
  );
}

export function ExtraDays() {
  return (
    <div className='small'>
      <p>
        If you set the extra days to 1, the student will get 1 extra day on the{' '}
        <i>due</i> date, and 1 extra day on the <i>available until</i> date.
        This means that the student will be able to submit the assignment 1 day
        after the <i>due</i> date, and the assignment will be available to the
        student for 1 day after the <i>available until</i> date.
      </p>
      <p>
        It is possible to override the <i>due</i> and <i>available until</i>{' '}
        dates for a single assignment. You can do that directly on Canvas. Make
        sure to create a new extension in Canvas, and do not use the existing
        one that was created by Extend.
      </p>
    </div>
  );
}

export function AssignmentEligibility() {
  return (
    <div className='small'>
      <p>
        Note that extra days are only applied to assignments that are in a group
        that contains the word <q>assignment</q> in the title (case
        insensitive). If you want to exclude certain assignments from receiving
        automatic extensions, put those assignments in groups that do not have
        the word <q>assignment</q> in the title.
      </p>
    </div>
  );
}

export function TimeMultiplier() {
  return (
    <div className='small'>
      <p>
        If you set the time multiplier to 1.5, the student will get 50% extra
        time on the quiz. This means that if the quiz is set to 10 minutes, the
        student will get 15 minutes to complete the quiz.
      </p>
    </div>
  );
}

export function QuizEligibility() {
  return (
    <div className='small'>
      <p>
        Note that the time multiplier is only applied to quizzes that are in a
        group that contains the word <q>quiz</q> in the title (case
        insensitive). If you want to exclude certain quizzes from receiving
        automatic time multipliers, put those quizzes in groups that do not have
        the word <q>quiz</q> in the title.
      </p>
      <p>
        Additionally, the time multiplier is only applied to Classic quizzes.
        New quizzes can be manually adjusted on Canvas.
      </p>
    </div>
  );
}

export function AutomaticUpdates() {
  return (
    <p className='small'>
      Every time you make a change or add new students in RamDesk, the
      accomodations are immediately entered into Canvas. However, if you make
      changes in Canvas, such as adding assignments or modifying due dates, the
      accomodations will be updated in Canvas by RamDesk later that night. For
      this reason, any updates to the accomodations will not be immediately
      visible on Canvas, but they will be visible the next day.
    </p>
  );
}

export function ExtendBestPractices() {
  return (
    <div className='small'>
      <ol>
        <li>
          Use Groups in Canvas to denote which assignments and quizzes should be
          affected.
        </li>
        <ul>
          <li>
            Only assignments in groups with the name &quot;assignment&quot;(case
            insensitive) will be affected.
          </li>
          <li>
            Only quizzes in groups with the name &quot;quiz&quot;(case
            insensitive) will be affected.
          </li>
        </ul>
        <li>Use Old Quizzes instead of New Quizzes</li>
        <ul>
          <li>
            New Quizzes are not supported by the extend tool and the extension
            defined here will only affect the old quizzes.
          </li>
          <li>
            If you are configuring new quizzes, we reccommend that you use Old
            Quizzes as we have found them to be easier to create and equal in
            terms of general capabilites.
          </li>
          <li>
            If you do need to set extensions on New Quizzes, you only have to do
            it once, and all new quizzes will be affected. This can be done in
            Canvas using build and moderate.
          </li>
        </ul>
        <li>
          Check the list of assignments and quizzes under the form to see which
          ones are affected. If you do not see an assignment there, please
          reference step 1.
        </li>
        <li>
          This tool should be used to give extensions to all assignments.
          Therefore, if you need to give extensions for a single
          assignment/quiz, use Canvas.
        </li>
        <li>
          Assignments and Quizzes assigned to only individuals/groups (not all
          of the students) are not affected by this tool. Use Canvas to set
          extensions for these courses.
        </li>
      </ol>
    </div>
  );
}
