import { memo, useEffect, useRef, useState } from 'react';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';

function SearchSelect({
  setIdName,
  items,
  idNameRef = null,
  idsToFilter = [],
  placeholder = null,
  isValid = null,
  isInvalid = null,
  required = false,
  setToNull = true,
  onSelect = () => null,
  displayId = false,
}) {
  const [hidden, setHidden] = useState(true);
  const [data, setData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currVal, setCurrVal] = useState('');
  const listRef = useRef(null);
  const defaultCurrVal = { id: null, name: '' };
  const formControlRef = useRef(null);

  const handleClickOutside = (e) => {
    if (listRef.current && !listRef.current.contains(e.target)) setHidden(true);
  };

  const getItems = (kw) => {
    if (items === null) return;
    return items.filter((item) => {
      for (const id of idsToFilter) {
        if (id === item.id) return false;
      }
      const lcItem = String(item.name).toLowerCase();
      const allItems = [
        lcItem,
        ...lcItem.split(/[^a-zA-Z0-9]/),
        String(item.id).toLowerCase(),
      ];
      for (const name of allItems) {
        if (name.startsWith(String(kw).toLowerCase())) return true;
      }
    });
  };

  useEffect(() => {
    if (items === null) return;
    if (setToNull) setIdName(defaultCurrVal);
    if (idNameRef !== null) {
      setCurrVal(idNameRef.current.name);
      setSelectedIndex(0);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setData(getItems(currVal));
  }, [currVal]);

  return (
    <div
      ref={listRef}
      style={{ position: 'relative' }}
      className='flex-grow-1 small'
      onKeyDown={(e) => {
        if (!hidden) {
          if (e.key === 'ArrowUp' && selectedIndex > 0)
            setSelectedIndex((prev) => prev - 1);
          if (e.key === 'ArrowDown' && selectedIndex < data.length - 1)
            setSelectedIndex((prev) => prev + 1);
          if (e.key === 'Enter' && data.length > 0) {
            onSelect(data[selectedIndex]);
            setHidden(true);
            if (idNameRef === null) setIdName(data[selectedIndex]);
            else idNameRef.current = data[selectedIndex];
            setCurrVal(data[selectedIndex].name);
          }
          if (e.key === 'Escape') setHidden(true);
        }
      }}
    >
      <InputGroup size='sm'>
        <Form.Control
          type={'text'}
          placeholder={placeholder}
          isValid={isValid}
          isInvalid={isInvalid}
          required={required}
          value={currVal}
          ref={formControlRef}
          onClick={(e) => {
            if (data === undefined) setData(getItems(currVal));
            e.target.select();
            setHidden(false);
          }}
          onChange={(e) => {
            if (idNameRef === null) {
              setIdName(defaultCurrVal);
            } else {
              if (e.target.value === '') {
                onSelect(defaultCurrVal);
                idNameRef.current = defaultCurrVal;
              }
            }
            setCurrVal(e.target.value);
            setSelectedIndex(0);
            setHidden(false);
          }}
          onKeyDown={(e) => {
            if (!hidden) {
              if (e.key === 'ArrowUp') e.preventDefault();
              if (e.key === 'ArrowDown') e.preventDefault();
              if (e.key === 'Enter') e.preventDefault();
            }
          }}
        ></Form.Control>
        <InputGroup.Text onClick={() => formControlRef.current.select()}>
          <i
            className={'bi bi-chevron-down'}
            onClick={() => setHidden(!hidden)}
            style={{ cursor: 'pointer' }}
          />
        </InputGroup.Text>
      </InputGroup>
      <ListGroup
        className='small w-100 overflow-auto'
        hidden={hidden}
        style={{ position: 'absolute', zIndex: 999, maxHeight: '400px' }}
      >
        {data &&
          data.map((item, i) => (
            <ListGroup.Item
              active={i === selectedIndex}
              action
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: '40px',
              }}
              key={i}
              onClick={(e) => {
                e.preventDefault();
                onSelect(data[i]);
                setHidden(true);
                if (idNameRef === null) setIdName(data[i]);
                else idNameRef.current = data[i];
                setCurrVal(data[i].name);
              }}
            >
              {`${item.name} ${displayId && item.id !== null ? `(${item.id})` : ''}`}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
}

export default memo(SearchSelect);
