import { memo, useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { DisplayOnError } from './Common';

function PlotsContainer({ data }) {
  const [relData, setRelData] = useState(null);
  const [currTab, setCurrTab] = useState(0);

  useEffect(() => {
    setRelData(data.filter((x) => x.display));
    setCurrTab(0);
  }, [data]);

  return (
    <Card className='border-0 h-100'>
      {relData && relData.length > 0 && (
        <>
          <Card.Header className='bg-white border-0'>
            <Tabs
              variant='pills'
              transition={false}
              activeKey={currTab}
              onSelect={(e) => setCurrTab(e)}
            >
              {relData.map((x, i) => {
                if (x.display)
                  return <Tab eventKey={i} key={i} title={x.title} />;
              })}
            </Tabs>
          </Card.Header>
          <div className='h-100 p-2'>
            <div className='h-100'>
              {
                (
                  relData[currTab] || {
                    element: (
                      <DisplayOnError
                        msg={'Please try to refresh the page and try again.'}
                      />
                    ),
                  }
                ).element
              }
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

export default memo(PlotsContainer);
