import { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function Help({
  title = 'Help',
  body = 'Nothing to show...',
  iconSize = '',
  margin = 'mx-2',
  label = null,
  variant = 'info',
}) {
  const [show, setShow] = useState(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      {label === null ? (
        <i
          className={`bi bi-question-circle-fill ${margin} text-${variant} ${iconSize}`}
          onClick={handleShow}
          style={{ cursor: 'pointer' }}
        ></i>
      ) : (
        <span
          className='d-flex align-items-center justify-content-end'
          onClick={handleShow}
          style={{ cursor: 'pointer' }}
        >
          <b>{label}</b>
          <i className='bi bi-question-circle-fill ms-2 text-info fs-2'></i>
        </span>
      )}
      <Modal show={show} centered size={'lg'} scrollable onHide={handleClose}>
        <Modal.Header
          className='bg-primary text-light'
          closeButton
          closeVariant='white'
        >
          <b>{title}</b>
        </Modal.Header>
        <Modal.Body
          style={{
            whiteSpace: 'pre-line',
            maxHeight: '70vh',
          }}
        >
          {body}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(Help);
