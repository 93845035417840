import { memo, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { reportProblem } from '../../api/misc_api';

function Report({ name }) {
  const [show, setShow] = useState(null);
  const [validated, setValidated] = useState(false);
  const [recMsg, setRecMsg] = useState(null);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    desc: null,
    steps: null,
  });

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setImages(false);
    setRecMsg(null);
    const newState = Object.fromEntries(
      Object.keys(formData).map((key) => [key, null]),
    );
    setFormData(newState);
  };

  const sendReport = (e) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      const data = new FormData();
      images.forEach((image, index) => {
        data.append(`image${index}`, image);
      });
      formData.name = name;
      data.append('data', JSON.stringify(formData));

      setRecMsg('Submitting...');
      reportProblem(data)
        .then(() => {
          setShow(false);
          toast.success('Successfully submitted report!');
        })
        .catch((error) => setRecMsg(error?.response?.data));
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: String(value) });
  };

  const handleImgChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const imageFiles = selectedFiles.filter((file) =>
      file.type.startsWith('image/'),
    );
    setImages([...imageFiles]);
  };

  return (
    <>
      <Dropdown.Item className='small' as={'button'} onClick={handleShow}>
        <i className={`bi bi-exclamation-triangle-fill`} />
        <span className='ms-2'>Report Bug</span>
      </Dropdown.Item>
      <Modal show={show} centered size={'lg'} scrollable onHide={handleClose}>
        <Modal.Header
          className='bg-primary text-light'
          closeButton
          closeVariant='white'
        >
          <b>Report a Problem</b>
        </Modal.Header>
        <Modal.Body
          style={{
            whiteSpace: 'pre-line',
            maxHeight: '70vh',
          }}
        >
          <Form
            onSubmit={sendReport}
            noValidate
            validated={validated}
            className='px-2'
          >
            <Form.Label>Describe Problem</Form.Label>
            <Form.Control
              size='sm'
              as='textarea'
              required
              onChange={handleChange}
              name='desc'
              rows={5}
            />
            <Form.Control.Feedback type='invalid'>
              You must describe what the problem is!
            </Form.Control.Feedback>
            <Form.Label className='mt-3'>
              Steps to Replicate Problem<i className='fw-normal'>(optional)</i>
            </Form.Label>
            <Form.Control
              size='sm'
              as='textarea'
              onChange={handleChange}
              name='steps'
              rows={3}
            />
            <Form.Label className='mt-3'>
              Screenshots <i className='fw-normal'>(optional)</i>
            </Form.Label>
            <Form.Control
              size='sm'
              type='file'
              multiple
              name='images'
              accept='image/*'
              onChange={handleImgChange}
            />
            <div className='text-end mt-4'>
              {recMsg && <span className='small'>{recMsg}</span>}
              <Button
                className='mx-1 text-light'
                variant='info'
                size='md'
                type={'submit'}
              >
                <i className='bi bi-floppy-fill'></i> Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(Report);
