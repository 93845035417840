import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { calculateMean } from '../../util/descriptive_statistics';
import {
  addMissingDate,
  COLORS,
  customLabel,
  DescriptiveStatistics,
  DisplayOnEmpty,
  DisplayOnError,
} from './Common';

export function SubmissionByHour({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const hourCounts = data.reduce((acc, i) => {
      const hour = new Date(i.submitted_at).getHours();
      acc[hour] = (acc[hour] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(hourCounts).map(([k, v]) => {
      return { Hour: Number(k), Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Hour'}
            label={{ value: 'Hour', position: 'insideBottom', offset: -10 }}
            type='number'
            ticks={[
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={'Could be because the submission date is invalid.'}
      />
    );
  }
}

export function SubmissionByDay({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const dayOrder = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayCounts = data.reduce((acc, i) => {
      const day = new Date(i.submitted_at).toLocaleString('en-us', {
        weekday: 'long',
      });
      acc[day] = (acc[day] || 0) + 1;
      return acc;
    }, {});
    dayOrder.forEach((x) => {
      if (dayCounts[x] === undefined) dayCounts[x] = 0;
    });
    const dataToPlot = Object.entries(dayCounts).map(([k, v]) => {
      return { Day: k, Count: v };
    });
    const sortedData = dataToPlot.sort((a, b) => {
      return dayOrder.indexOf(a.Day) - dayOrder.indexOf(b.Day);
    });
    return (
      <ResponsiveContainer>
        <BarChart data={sortedData} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Day'}
            label={{ value: 'Day', position: 'insideBottom', offset: -10 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={'Could be because the submission date is invalid.'}
      />
    );
  }
}

export function GradeByAttempts({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const stuAttempts = data.reduce((acc, d) => {
      const key = d.student_id;
      if (isNaN(Number(d.grade))) return acc;

      const value = { date: new Date(d.submitted_at), score: d.grade };
      acc[key] = [...(acc[key] || []), value];
      return acc;
    }, {});
    const sortedStuAttempts = Object.values(stuAttempts).map((x) =>
      x.sort((a, b) => a.date - b.date),
    );
    const sortedStuGrades = sortedStuAttempts.map((x) =>
      x.map((x) => Number(x.score)),
    );
    const transposedGrades = sortedStuGrades.reduce((acc, row) => {
      row.forEach((val, colIdx) => {
        if (!acc[colIdx]) acc[colIdx] = [];
        acc[colIdx].push(val);
      });
      return acc;
    }, []);
    const dataToPlot = transposedGrades.map((x, i) => {
      return { Attempt: i + 1, Score: calculateMean(x), Count: x.length };
    });
    return (
      <ResponsiveContainer>
        <LineChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Attempt'}
            label={{ value: 'Attempt', position: 'insideBottom', offset: -5 }}
            type='number'
          />
          <YAxis
            yAxisId={'score'}
            label={{ value: 'Score', angle: -90, dx: -15, fill: COLORS[1] }}
            type='number'
            stroke='#008fb3'
          />
          <YAxis
            yAxisId={'count'}
            label={{ value: 'Count', angle: -90, dx: 15, fill: COLORS[0] }}
            orientation='right'
            type='number'
            stroke='#e56a54'
          />
          <Legend />
          <Tooltip />
          <Line
            yAxisId='score'
            dataKey={'Score'}
            fill={COLORS[0]}
            type={'monotone'}
          />
          <Line
            yAxisId='count'
            dataKey={'Count'}
            fill={COLORS[1]}
            type={'basis'}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={
          'Could be because the grade is not a number (i.e. 70%, A, pass, etc.) or the submission date is invalid.'
        }
      />
    );
  }
}

export function ScoreDistribution({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const allGrades = data
      .map((x) => Number(x.grade))
      .filter((x) => !isNaN(x))
      .sort((a, b) => a - b);
    const gradeCounts = allGrades.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(gradeCounts).map(([k, v]) => {
      return { Score: Number(k), Count: v };
    });

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Score'}
            label={{ value: 'Score', position: 'insideBottom', offset: -5 }}
            type='number'
            interval={0}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
          <Legend
            content={<DescriptiveStatistics sortedData={allGrades} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={
          'Could be because the grade is not a number (i.e. 70%, A, pass, etc.) or the submission date is invalid.'
        }
      />
    );
  }
}

export function AttemptDistribution({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const stuAttempts = data.reduce((acc, i) => {
      const id = i.student_id;
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const allAttempts = Object.values(stuAttempts).sort((a, b) => a - b);
    const attemptCounts = allAttempts.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(attemptCounts).map(([k, v]) => {
      return { Attempts: Number(k), Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Attempts'}
            type='number'
            label={{
              value: 'Attempts',
              position: 'insideBottom',
              offset: -5,
            }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
          <Legend
            content={<DescriptiveStatistics sortedData={allAttempts} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError msg={''} />;
  }
}

export function SubmissionByDate({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const dayCounts = data.reduce((acc, i) => {
      const date = new Date(i.submitted_at).toLocaleString('en-us', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(dayCounts).map(([k, v]) => {
      return { Date: k, Count: v };
    });
    const sortedData = dataToPlot.sort((a, b) => {
      return a.Date.localeCompare(b.Date);
    });
    const fullData = addMissingDate(sortedData);
    return (
      <ResponsiveContainer>
        <BarChart data={fullData} margin={{ bottom: 70, left: 40, right: 40 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Date'}
            label={{ value: 'Date', position: 'insideBottom', offset: -60 }}
            tickCount={sortedData.length}
            textAnchor='start'
            angle={45}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={'Could be because the submission date is invalid.'}
      />
    );
  }
}

export function ScoreOnLastAttempt({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const stuAttempts = data.reduce((acc, d) => {
      const key = d.student_id;
      if (isNaN(Number(d.grade))) return acc;

      const value = { date: new Date(d.submitted_at), score: d.grade };
      acc[key] = [...(acc[key] || []), value];
      return acc;
    }, {});
    const sortedStuAttempts = Object.values(stuAttempts).map((x) =>
      x.sort((a, b) => a.date - b.date),
    );
    const sortedStuGrades = sortedStuAttempts.map((x) =>
      x.map((x) => Number(x.score)),
    );
    const lastGrades = sortedStuGrades
      .map((x) => x[x.length - 1])
      .sort((a, b) => a - b);
    const countsOfLastGrades = lastGrades.reduce((acc, x) => {
      acc[x] = (acc[x] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(countsOfLastGrades).map(([k, v]) => {
      return { Score: Number(k), Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Score'}
            label={{ value: 'Score', position: 'insideBottom', offset: -5 }}
            type='number'
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
          <Legend
            content={<DescriptiveStatistics sortedData={lastGrades} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return (
      <DisplayOnError
        msg={
          'Could be because the grade is not a number (i.e. 70%, A, pass, etc.) or the submission date is invalid.'
        }
      />
    );
  }
}

export function GraderStats({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  let dataToPlot = [
    {
      name: 'Grader Status',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Grader Type',
      count1: 0,
      count2: 0,
      name1: 'Delay',
      name2: 'Schedule',
    },
    {
      name: 'Canvas Settings',
      count1: 0,
      count2: 0,
      name1: 'Keep Highest',
      name2: 'Keep Latest',
    },
    {
      name: 'Networking',
      count1: 0,
      count2: 0,
      name1: 'Enabled',
      name2: 'Disabled',
    },
  ];

  for (const d of data) {
    d.active ? (dataToPlot[0].count1 += 1) : (dataToPlot[0].count2 += 1);
    Object.prototype.hasOwnProperty.call(d.schedule, 'delay_minutes')
      ? (dataToPlot[1].count1 += 1)
      : (dataToPlot[1].count2 += 1);
    d.keep_highest ? (dataToPlot[2].count1 += 1) : (dataToPlot[2].count2 += 1);
    d.networking ? (dataToPlot[3].count1 += 1) : (dataToPlot[3].count2 += 1);
  }
  return (
    <ResponsiveContainer>
      <BarChart
        layout='vertical'
        data={dataToPlot}
        margin={{ left: 20, right: 20, bottom: 10 }}
      >
        <CartesianGrid />
        <XAxis
          type='number'
          domain={[0, data.length]}
          label={{
            value: 'Num Graders',
            position: 'insideBottom',
            offset: -5,
          }}
        />
        <YAxis dataKey='name' type='category' tick={{ fontSize: '12' }} />
        <Bar
          dataKey='count1'
          stackId='a'
          fill={COLORS[0]}
          label={(props) =>
            customLabel({ ...props, key: '1', data: dataToPlot })
          }
        />
        <Bar
          dataKey='count2'
          stackId='a'
          fill={COLORS[1]}
          label={(props) =>
            customLabel({ ...props, key: '2', data: dataToPlot })
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export function TaskUsage({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  let dataToPlot = [
    {
      name: 'Extract',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Check Code',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Check Files',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Make',
      count1: 0,
      count2: 0,
      name1: 'Active',
      name2: 'Inactive',
    },
    {
      name: 'Run Type',
      count1: 0,
      count2: 0,
      count3: 0,
      name1: 'Cloud',
      name2: 'SSH',
      name3: 'Checkin',
    },
  ];

  for (const d of data) {
    d.tasks.extract.enabled
      ? (dataToPlot[0].count1 += 1)
      : (dataToPlot[0].count2 += 1);
    d.tasks.check_code.enabled
      ? (dataToPlot[1].count1 += 1)
      : (dataToPlot[1].count2 += 1);
    d.tasks.check_files.enabled
      ? (dataToPlot[2].count1 += 1)
      : (dataToPlot[2].count2 += 1);
    d.tasks.make.enabled
      ? (dataToPlot[3].count1 += 1)
      : (dataToPlot[3].count2 += 1);
    if (d.tasks.run.type === 'custom') {
      d.tasks.run.location === 'cloud'
        ? (dataToPlot[4].count1 += 1)
        : (dataToPlot[4].count2 += 1);
    } else dataToPlot[4].count3 += 1;
  }

  return (
    <ResponsiveContainer>
      <BarChart
        layout='vertical'
        data={dataToPlot}
        margin={{ left: 20, right: 20, bottom: 10 }}
      >
        <CartesianGrid />
        <XAxis
          type='number'
          domain={[0, data.length]}
          label={{
            value: 'Num Graders',
            position: 'insideBottom',
            offset: -5,
          }}
        />
        <YAxis dataKey='name' type='category' tick={{ fontSize: '12' }} />
        <Bar
          dataKey='count1'
          stackId='a'
          fill={COLORS[0]}
          label={(props) =>
            customLabel({ ...props, key: '1', data: dataToPlot })
          }
        />
        <Bar
          dataKey='count2'
          stackId='a'
          fill={COLORS[1]}
          label={(props) =>
            customLabel({ ...props, key: '2', data: dataToPlot })
          }
        />
        <Bar
          dataKey='count3'
          stackId='a'
          fill={COLORS[2]}
          label={(props) =>
            customLabel({ ...props, key: '3', data: dataToPlot })
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export function GradersPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function SubmissionsPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      acc[key] = (acc[key] || 0) + (i.submissions || []).length;
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}
