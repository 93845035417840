import axios from 'axios';
import { BACKEND_URL } from './api.js';

export default function getStudentsForCourse(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/students')
    .then((response) =>
      response.data.sort((a, b) => a.name.localeCompare(b.name)),
    );
}
